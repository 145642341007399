import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import cx from 'classnames';
import dgaLogoSVG from '../../img/dga-logo.svg';
import Gabriel_Duursma from '../../img/contact/Gabriel_Duursma.jpg';
import factsheet from '../../factsheet-hybride-groene-aggregaat-verhuur.pdf';

export class MarketingModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            spinner: false,
            formComplete: false,
        };
    }

    requestComplete = response => {
        this.setState({ spinner: false, formComplete: true });
        console.log(JSON.parse(response));
    };

    handleSubmit = e => {
        const { requestComplete } = this;
        const { onThanks, offerteModal, model, doelgroep } = this.props;
        e.preventDefault();
        let data;

        if (offerteModal) {
           data = {
               name: e.target.Naam.value,
               company: e.target.Bedrijf.value,
               phone: e.target.Telefoonnummer.value,
               email: e.target['E-mailadres'].value,
               message: e.target['Bericht'].value,
               type: this.props.doelgroep,
               model: this.props.model,
           }
        } else {
            data = {
                name: e.target.Voornaam.value,
                surname: e.target.Achternaam.value,
                company: e.target.Bedrijf.value,
                function: e.target.Functie.value,
                phone: e.target.Telefoonnummer.value,
                email: e.target['E-mailadres'].value,
                interest: e.target['Interesse-gd'].checked ? 'Ja' : 'Nee',
                type: this.props.doelgroep,
            }
        }

        this.setState({ spinner: true });

        const formUrl = offerteModal ? '/api/landing-quote' : '/api/landing-portfolio';

        axios({
            method: 'post',
            url: formUrl,
            data: data,
            config: { headers: { 'Content-Type': 'multipart/form-data' } },
        })
            .then(function(response) {
                //handle success
                console.log('SUCCESS!!!!!');
                onThanks(model, doelgroep);
                return JSON.stringify(response);
            })
            .catch(function(response) {
                //handle error
                return JSON.stringify(response);
            })
            .then(requestComplete);
    };
    render() {
        return (
            <Modal backdrop={this.props.visibility} show={this.props.visibility} onHide={this.props.onClick}>
                <Modal.Body>
                    <div
                        className={cx('modal-question', {
                            'modal-question-open': this.props.visibility,
                            'modal-question-close': !this.props.visibility,
                        })}
                    >
                        <div className="bug-free-scroll">
                            <button
                                className="modal-question__button-close modal-question__button-close--invisible site-button button-reset"
                                type="button"
                                onClick={() => {
                                    this.setState({ spinner: false, formComplete: false });
                                    this.props.onClick();
                                }}
                            >
                                <i className={'material-icons'}>close</i>
                                <span>Sluit</span>
                            </button>
                            {!this.state.formComplete && (
                                <h2 style={{ marginTop: '50px' }} className="modal-question__heading">
                                    {this.props.offerteModal ? `Vraag offerte aan voor ${this.props.model}` : 'Aanvraag meer informatie'}
                                </h2>
                            )}

                            {this.props.offerteModal && !this.state.formComplete && (
                                <div style={{ textAlign: 'right' }}>
                                    <img src={Gabriel_Duursma} width="200" height="200" alt="Foto van een medewerker" />
                                </div>
                            )}

                            {this.state.formComplete ? (
                                <div style={{ textAlign: 'left' }}>
                                    <h2 style={{ marginTop: '50px' }}>
                                        {this.props.offerteModal ? `BEDANKT VOOR UW INTERESSE IN ${this.props.model}` : 'BEDANKT VOOR UW INTERESSE IN GROENE ENERGIE.'}{' '}
                                    </h2>
                                    {this.props.offerteModal ? (
                                        <div style={{ color: '#797979' }}>Een van onze specialisten neemt binnenkort contact met u op. </div>
                                    ) : (
                                        <a
                                            className={'brochure-modal-button'}
                                            onClick={e => {
                                                e.preventDefault();
                                                window.open(factsheet);
                                            }}
                                            href={factsheet}
                                            alt="Bekijk brochure"
                                            target={'_blank'}
                                            download
                                        >
                                            Bekijk brochure
                                        </a>
                                    )}
                                    <br />
                                    <div style={{ color: '#797979' }}>
                                        {!this.props.offerteModal
                                            ? 'Mocht u met meer vragen zitten, dan zijn wij te bereiken op:'
                                            : 'Mocht u voor die tijd met vragen zitten, dan zijn wij te bereiken op:'}
                                    </div>
                                    <br />
                                    <div style={{ color: '#797979', fontWeight: 'bold' }}>
                                        <a style={{ color: '#797979', fontWeight: 'bold' }} href={'tel:+31299416641'}>
                                            0299-41 66 41 of
                                        </a>
                                        <br />
                                        <a style={{ color: '#797979', fontWeight: 'bold' }} href={'mailto:verhuur@degroeneaggregaat.nl'}>
                                            verhuur@degroeneaggregaat.nl
                                        </a>
                                    </div>
                                    <br />
                                    <div style={{ color: '#797979' }}>Fijne dag!</div>

                                    <div style={{ width: '300px', maxWidth: '300px', marginTop: '25px' }}>
                                        <img className="contact__logo" src={dgaLogoSVG} width="600" height="224" style={{ width: '100%' }} alt="De groene aggregaat logo" />
                                    </div>
                                </div>
                            ) : this.state.spinner ? (
                                <p>Gegevens verzenden...</p>
                            ) : (
                                <form className="question-form" method="post" onSubmit={this.handleSubmit}>
                                    {/* Formulier Brochure */}
                                    {!this.props.offerteModal && (
                                        <div className="form-container">
                                            <input type="text" required name="Voornaam" placeholder="Voornaam" />
                                            <input type="text" required name="Achternaam" placeholder="Achternaam" />
                                            <input type="text" required name="Bedrijf" placeholder="Bedrijf" />
                                            <input type="text" required name="Functie" placeholder="Functie" />
                                            <input type="tel" required name="Telefoonnummer" placeholder="Telefoonnummer" />
                                            <input type="email" required name="E-mailadres" placeholder="E-mailadres" />

                                            <div style={{ display: 'flex', marginTop: '15px', marginBottom: '15px' }}>
                                                <input type="checkbox" id={'Interesse-gd'} style={{ width: '35px' }} name="Interesse-gd" placeholder="Interesse-gd" />
                                                <label htmlFor="Interesse-gd" style={{ fontSize: '17px', textAlign: 'left', color: '#797979' }}>
                                                    Ik heb interesse in meer informatie over De Groene Inruildeals.
                                                </label>
                                            </div>

                                            <button type="submit">Verzend</button>
                                        </div>
                                    )}

                                    {/* Formulier Offerte */}
                                    {this.props.offerteModal && (
                                        <div className="form-container">
                                            <input type="text" required name="Naam" placeholder="Naam" />
                                            <input type="text" required name="Bedrijf" placeholder="Bedrijf" />
                                            <input type="tel" required name="Telefoonnummer" placeholder="Telefoonnummer" />
                                            <input type="email" required name="E-mailadres" placeholder="E-mailadres" />
                                            <input type="text" required name="Bericht" placeholder="Bericht" />

                                            <button type="submit">Verzend</button>
                                        </div>
                                    )}
                                </form>
                            )}
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
