import React from 'react';
import Slider from 'react-slick';
import './Slick.scss';

export default class ReactSlickDemo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            slideIndex: props.initialSlide,
        };
    }
    componentDidUpdate() {
        this.slider.slickGoTo(this.props.initialSlide);
    }

    onClickGoToModel = slide => {
        // read the current slide index from state -->
        const { slideIndex } = this.state;

        if (slideIndex === 0 && slide === 4) {
            this.slider.slickPrev();
            return;
        }

        if (slideIndex === 4 && slide === 0) {
            this.slider.slickNext();
            return;
        }

        if (slide > slideIndex) {
            this.slider.slickNext();
        }
        if (slide < slideIndex) {
            this.slider.slickPrev();
        }

    };

    render() {
        let settings = {
            dots: false,
        };
        console.log('initialSlide prop --> ', this.props.initialSlide);

        const { onClickGoToModel } = this;

        return (
            <div className="container">
                <Slider
                    slidesToShow={3}
                    ref={c => (this.slider = c)}
                    initialSlide={this.props.initialSlide}
                    afterChange={args => {
                        console.log('Swipe animation is done');
                        this.setState({ slideIndex: args });
                        this.props.onAfterChange(args);
                    }}
                    onSwipe={() => console.log('Finger is not on screen anymore --> ')}
                    {...settings}
                >
                    <div onClick={() => onClickGoToModel(4)}>
                        <p>Model 5</p>
                    </div>
                    <div onClick={() => onClickGoToModel(0)}>
                        <p>Model 30</p>
                    </div>
                    <div onClick={() => onClickGoToModel(1)}>
                        <p>Model 45</p>
                    </div>
                    <div onClick={() => onClickGoToModel(2)}>
                        <p>Model 100</p>
                    </div>
                    <div onClick={() => onClickGoToModel(3)}>
                        <p>Maatwerk</p>
                    </div>
                </Slider>
            </div>
        );
    }
}
