// Modules
import React from 'react';

// Styling
import './OnzeVisie.scss';

// Images
import model100 from '../../img/home-models/model-100/huur-onze-groene-aggregaat-model-100-hybride-aggregaat-met-zonnepanelen-veel-vermogen-en-laag-co2-uitstoot-new.png';

import iconCO2 from '../../img/onze-visie/icon CO2.svg';
import iconGeluid from '../../img/onze-visie/icon geluid.svg';
import iconKnop from '../../img/onze-visie/icon knop.svg';
import iconWinst from '../../img/onze-visie/icon winstgevend.svg';
import { Helmet } from 'react-helmet-async';

class AccordionBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        };
    }

    setExpanded = expanded => {
        this.setState({ expanded });
    };
    render() {
        const { expanded } = this.state;
        const { type, content, text } = this.props;
        return (
            <div className={type}>
                <div className={'trigger'} onClick={() => this.setExpanded(!expanded)}>
                    <p>{text}</p>
                    <i className={'material-icons'}>{expanded ? 'arrow_drop_up' : 'arrow_drop_down'} </i>
                </div>
                <div className={expanded ? 'content-visible' : 'content-hidden'}>{content}</div>
            </div>
        );
    }
}

const OnzeVisie = props => {
    return (
        <main className={'onze-visie-page'}>
            <Helmet>
                <title>Onze Visie | De Groene Aggregaat Verhuur</title>
                <meta name="description" content="Lees hier alles over ons bedrijf, onze visie en onze producten" />
            </Helmet>
            <section className={'first-section'}>
                <h1>Even alles op een rij</h1>
                <p>Lees hier alles over ons bedrijf, onze visie en onze producten</p>
            </section>
            <section>
                <AccordionBox
                    text={window.innerWidth < 751 ? 'WIE ZIJN WIJ?' : 'VAN AMBITIE TOT PRODUCT. WIE ZIJN WIJ?'}
                    type={'box grey-box'}
                    content={
                        <div className="box-content">
                            <h3>Maru systems</h3>
                            <p>
                                De Groene Aggregaat vindt zijn vroege oorsprong in 2007 als RUSO technische dienstverlening. De ontwikkeling van verschillende innovatieve ideeën en
                                producten liep snel voorspoedig binnen RUSO, waardoor de stap naar een overkoepelde organisatie gewenst werd. Hieruit werd Maru Systems geboren: een
                                overkoepelde organisatie welke zich richt op een internationale afzetmarkt voor functioneel duurzame, zelf ontwikkelde en innovatieve machines. Deze
                                producten vinden hun afzet binnen de doelgroep solar, off grid energy supply solutions.
                            </p>
                            <img src={model100} alt= "de groene hybride aggregaat is ontwikkeld door maru systems en nu te huur"/>
                            <p>
                                Eén van deze innovatieve producten is de Groene Aggregaat. Een hybride aggregaat welke een off grid en duurzaam alternatief biedt voor vervuilende,
                                conventionele aggregaten. Dit product is ontwikkeld binnen onze R&D afdeling in Zuidoostbeemster, waar wij in onze eigen werkplaats het gehele
                                proces van de eerste schets tot de laatste bout beheren. Hierdoor kunnen wij de Groene Aggregaat als een betrouwbaar product neerzetten en tevens
                                adequate support leveren op alle functionaliteiten. Daarnaast blijft ons R&D team te allen tijde bezig met de ontwikkeling en verbetering van de
                                Groene Aggregaat, waardoor deze continue zal blijven voldoen aan de laatste milieueisen en prestatieniveaus
                            </p>
                        </div>
                    }
                />
                <AccordionBox
                    text={'WAT IS EEN HYBRIDE AGGREGAAT?'}
                    type={'box blue-box'}
                    content={
                        <div className="box-content">
                            <h3>De groene aggregaat</h3>
                            <p>
                                Onze aggregaat is een hybride aggregaat. Dit houdt in dat deze zijn energie haalt uit een samenwerking tussen verschillende energiebronnen. De
                                Groene Aggregaat is namelijk naast een brandstofmotor uitgerust met een accupakket. Door deze hybride oplossing verbruikt de Groene Aggregaat veel
                                minder kostbare en vervuilende brandstof dan een conventionele aggregaat.
                            </p>
                            <p>
                                Om de CO2 uitstoot te reduceren, worden de accu’s opgeladen door zonnepanelen die bij installatie automatisch en binnen twee minuten uit de
                                behuizing komen. Zo creëert u in korte tijd een off-grid (zelfstandige) energieoplossing met hart voor het milieu én portemonnee.
                            </p>
                            <blockquote>"Onze groene modellen bieden duurzame alternatieven voor conventionele aggregaten op iedere locatie"</blockquote>
                        </div>
                    }
                />
                <AccordionBox
                    text={'WAT ZIJN DE VOORDELEN?'}
                    type={'box green-box'}
                    content={
                        <div className="box-content">
                            <div className="header-with-icon">
                                <img src={iconWinst} alt= "de groene aagregaat is winstgevend"/>
                                <div>
                                    <h3>Winstgevend</h3>
                                    <h5>Duurzaam bezig zijn en nog fiscaal voordelig ook?</h5>
                                </div>
                            </div>

                            <p>
                                De Groene Aggregaat is ontwikkeld vanuit een simpele visie: het moet functioneel duurzaam zijn. Voor duurzame doelen stellen wij als producent niet
                                alleen de professionele markt. Wij zien graag dat de Groene Aggregaat door iedereen gebruikt kan worden. Daarom is de Groene Aggregaat functioneel,
                                financieel en operationeel een interessante oplossing.
                                <br /> Financieel is de Groene Aggregaat zelfs zeer interessant vanwege haar gunstige ROI en de positieve invloed op onder andere Co2 reductie en
                                geluidsoverlast.
                            </p>
                            <div className={'horizontal-rule'} />

                            <div className="header-with-icon">
                                <img src={iconCO2} alt= "de groene aagregaat is milieuvriendelijk"/>
                                <div>
                                    <h3>MEER DAN 90% CO2 REDUCTIE</h3>
                                    <h5>Onnodige CO2 uitstoot kan echt niet meer!</h5>
                                </div>
                            </div>

                            <p>
                                CO2 reductie is onze grootste passie als het gaat om het ontwikkelen van allerlei duurzame oplossingen binnen de energiebehoefte. Deze passie en
                                onze liefde voor het ontwikkelen, heeft er toe geleid dat de Groene Aggregaat momenteel een grote bijdrage levert aan het verbeteren van uitstoot in
                                relatie tot het milieu. En daar zijn we trots op!
                                <br />
                                CO2 reductie is en wordt een steeds belangrijker onderdeel van de zakelijke bedrijfsvoering voor allerlei sectoren. Maar ook in de private markt zie
                                je een verschuiving van het bewustzijn naar een steeds groenere wereld. De Groene Aggregaat sluit aan bij deze groene behoeften vanwege zijn moderne
                                hybride technologie, welke een CO2 reductie oplevert van meer dan 90%
                            </p>
                            <div className={'horizontal-rule'} />

                            <div className="header-with-icon">
                                <img src={iconKnop} alt= "de groene aagregaat is gemakkelijk in gebruik" />
                                <div>
                                    <h3>GEMAKKELIJK IN GEBRUIK</h3>
                                    <h5>Waarom moeilijk doen als het makkelijk kan?</h5>
                                </div>
                            </div>

                            <p>
                                Wij kiezen er bewust voor de Groene Aggregaat qua bediening zo simpel mogelijk te houden, zodat iedere gebruiker er eenvoudig mee uit de voeten kan.
                                <br />
                                Dat de bediening simpel is, wil niet zeggen dat de techniek erachter dat ook is! De Groene Aggregaat is bijvoorbeeld uitgerust met een management
                                systeem dat van afstand bestuurd kan worden. Hierdoor kunt u ten alle tijden uw hybride aggregaat monitoren en aansturen.
                            </p>
                            <div className={'horizontal-rule'} />

                            <div className="header-with-icon">
                                <img src={iconGeluid} alt= "de groene aagregaat is geluidsarm"/>
                                <div>
                                    <h3>GELUIDSARM</h3>
                                    <h5>Waarom herrie als het ook stiller of helemaal geluidsvrij kan?</h5>
                                </div>
                            </div>

                            <p>
                                Ook hybride aggregaten maken geluid, helaas. Maar door de slimme functionaliteit van de Groene Aggregaat heeft u daar zelf controle over.
                                <br /> De Groene Aggregaat maakt namelijk alleen geluid wanneer het nodig is. Dit is volledig zelf te regelen door de gebruiker; u bepaalt wanneer
                                de aggregaat aanslaat. Is het bijvoorbeeld in stedelijk gebied gewenst een nacht door te draaien zonder geluidsproductie? Dan kan de Groene
                                Aggregaat door zijn hybride functionaliteit zo ingesteld worden dat deze ’s nacht vermogen levert zonder ook maar één decibel aan geluid te
                                produceren.
                            </p>
                        </div>
                    }
                />
            </section>
            <div className={'onze-visie-background-image'} />
        </main>
    );
};

export default OnzeVisie;
