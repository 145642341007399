// Modules
import { takeEvery } from "redux-saga/effects";

export function* rootSaga() {
  // for logging purposes...
  yield takeEvery("*", logActionType);
}

const logActionType = ({ type, payload }) =>
  console.debug(" *** Action is dispatched now! *** ", type);
