export const modelsRoutes = [
    { uri: "/model-5", label: "Model 5" },
    { uri: "/model-30", label: "Model 30" },
    { uri: "/model-45", label: "Model 45" },
    {
        uri: "/model-100",
        label: "Model 100"
    }
];

export const navigationRoutes = [
    {
        uri: "/maatwerk",
        label: "Maatwerk",
        classBem: "main-nav__list-item--custom"
    },
    {
        uri: "/green-deals",
        label: "Green deals",
        classBem: "main-nav__list-item--custom"
    },
    {
        uri: "/in-actie",
        label: "In actie",
        classBem: "main-nav__list-item--action"
    },
    {
        uri: "/onze-visie",
        label: "Onze visie",
        classBem: "main-nav__list-item--vision"
    },
    {
        uri: "/contact",
        label: "Contact",
        classBem: "main-nav__list-item--contact"
    }
];
