import React from 'react';

const MadeBy = () => {
    return (
        <div className="made-by">
            Website by{' '}
            <a href="https://www.appyourweb.nl" rel="noopener noreferrer" target="_blank" aria-label={'Website by App your Web'}>
                App your Web
            </a>
        </div>
    );
};

export default MadeBy;
