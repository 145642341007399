// Modules
import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

// Styling
import './Footer.scss';
import './FooterMobile.scss';

// Images
import footerSVG from '../img/logo-dga-footer.svg';

const Footer = () => (
    <footer className="page-footer">
        <ul className="page-footer__list">
            <li className="page-footer__list-item page-footer__list-item--logo">
                <NavLink to={'/'} className={'page-footer__link site-link'}>
                    <img src={footerSVG} width="252" height="84" alt="De groene aggregaat logo" />
                </NavLink>
            </li>
            <li className="page-footer__list-item page-footer__list-item--adress">
                <p>
                    Vredenburghweg 2<br />
                    1461 GT Zuidoostbeemster
                </p>
                <p>
                    KVK: 67204155 <br />
                    BTW: NL85 68744 74B01
                </p>
            </li>
            <li className="page-footer__list-item page-footer__list-item--contact">
                <p>
                    T: +31 299 – 41 66 41
                    <br />
                    verhuur@degroeneaggregaat.nl
                </p>
                {/*}<p>
                    T +31 299 -  41 63 03
                    <br />
                    info@degroeneaggregaat.nl
                </p>{*/}
            </li>
            <li className="page-footer__list-item page-footer__list-item--social">
                <ul className="page-footer__social">
                    <li className="page-footer__social-item">
                        <a
                            className="page-footer__social-link text-hide"
                            rel="noopener noreferrer"
                            href="https://www.linkedin.com/showcase/de-groene-aggregaat/"
                            target="_blank"
                            aria-label="De Groene Aggregaat op LinkedIn"
                        >
                            <svg className="page-footer__social-icon linked-in" width="67" height="67" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <title>linkedin logo</title>
                                <g id="Laag_linkedin_2" data-name="Laag 2">
                                    <g id="Laag_linkedin" data-name="Laag 1">
                                        <path
                                            id="Linkedin"
                                            className="cls-1"
                                            d="M60,30A30,30,0,1,1,30,0,30,30,0,0,1,60,30ZM13.63,46.66h7V24.26h-7Zm3.49-33.54a4,4,0,1,0,4,4,4,4,0,0,0-4-4M25,46.66h7V35.58c0-2.92.55-5.76,4.18-5.76s3.61,3.35,3.61,5.94v10.9h7V34.37c0-6-1.31-10.67-8.36-10.67a7.3,7.3,0,0,0-6.58,3.62h-.1V24.26H25Z"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </li>
                    <li className="page-footer__social-item">
                        <a
                            className="page-footer__social-link text-hide"
                            rel="noopener noreferrer"
                            href="https://www.youtube.com/channel/UCXUZblOpiS7cqc6jXzQvFKw"
                            target="_blank"
                            aria-label="De Groene Aggregaat op Youtube"
                        >
                            <svg className="page-footer__social-icon youtube" width="67" height="67" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <title>youtube logo</title>
                                <g id="Laag_youtube_2" data-name="Laag 2">
                                    <g id="Laag_youtube" data-name="Laag 1">
                                        <path
                                            id="YouTube"
                                            className="cls-1"
                                            d="M60,30A30,30,0,1,1,30,0,30,30,0,0,1,60,30ZM46.8,23.28a4.81,4.81,0,0,0-4.8-4.8H18a4.81,4.81,0,0,0-4.8,4.8V36.72a4.81,4.81,0,0,0,4.8,4.8H42a4.81,4.81,0,0,0,4.8-4.8Zm-20.27,1.4,9.08,4.74-9.08,4.7Z"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </li>
                </ul>
            </li>
            <li className="page-footer__list-item page-footer__list-item--links">
                <ul className="page-footer__links">
                    <li className="page-footer__links-item">
                        <NavLink to={'/model-5'} className={'page-footer__link site-link'}>
                            Model 5
                        </NavLink>
                    </li>
                    <li className="page-footer__links-item">
                        <NavLink to={'/model-30'} className={'page-footer__link site-link'}>
                            Model 30
                        </NavLink>
                    </li>
                    <li className="page-footer__links-item">
                        <NavLink to={'/model-45'} className={'page-footer__link site-link'}>
                            Model 45
                        </NavLink>
                    </li>
                    <li className="page-footer__links-item">
                        <NavLink to={'/model-100'} className={'page-footer__link site-link'}>
                            Model 100
                        </NavLink>
                    </li>
                    <li className="page-footer__links-item">
                        <NavLink to={'/maatwerk'} className={'page-footer__link site-link'}>
                            Maatwerk
                        </NavLink>
                    </li>
                    <li className="page-footer__links-item">
                        <NavLink to={'/in-actie'} className={'page-footer__link site-link'}>
                            In actie
                        </NavLink>
                    </li>
                    <li className="page-footer__links-item">
                        <NavLink to={'/onze-visie'} className={'page-footer__link site-link'}>
                            Onze visie
                        </NavLink>
                    </li>
                    <li className="page-footer__links-item">
                        <NavLink to={'/contact'} className={'page-footer__link site-link'}>
                            Contact
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="page-footer__list-item page-footer__list-item--links2">
                <ul className="page-footer__links2">
                    <li className="page-footer__links2-item">
                        <a className="page-footer__links2-link site-link" rel="noopener" target={'_blank'} href="https://www.maru.energy/">
                            MARU
                        </a>
                    </li>
                    <li className="page-footer__links2-item">
                        <a className="page-footer__links2-link site-link" rel="noopener" target={'_blank'} href="https://maru.solar">
                            Maru Solar
                        </a>
                    </li>
                    <li className="page-footer__links2-item">
                        <a className="page-footer__links2-link site-link" rel="noopener" target={'_blank'} href="https://thegreengenerators.com/">
                            The Green Generators
                        </a>
                    </li>
                </ul>
            </li>
            <li className="page-footer__list-item page-footer__list-item--copyright">
                <p className="page-footer__copyright">© MARU {moment().format('YYYY')}</p>
            </li>
        </ul>
    </footer>
);

export default Footer;
