// Modules
import React from 'react';

// Styling
import './MDCookieBanner.scss';

class MDCookieBanner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: this.isCookieInstalled(),
        };
    }

    componentDidMount() {
        // did we already accept?
        this.isCookieInstalled() && this.props.accept();
    }

    // expired cookies get deleted by the browser automatically
    isCookieInstalled = () => !!document.cookie;

    closeBanner = () => {
        this.setState({ hidden: true });
    };

    render() {
        // No banner if cookie is installed already
        if (this.state.hidden) {
            return <></>;
        }
        const { accept } = this.props;

        return (
            <article className={'md-cookie-banner'}>
                <div className={'cookie-section'}>
                    Via onze website wordt een cookie geplaatst van het Amerikaanse bedrijf Google, als deel van de "Analytics"-dienst. <br />
                    Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe bezoekers de website gebruiken. <br />
                    Google kan deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, <br />
                    of voor zover derden de informatie namens Google verwerken. Wij hebben hier geen invloed op. <br />
                    De informatie die Google verzamelt wordt zo veel mogelijk geanonimiseerd. Uw IP-adres wordt nadrukkelijk niet meegegeven. <br />
                    De informatie wordt overgebracht naar en door Google opgeslagen op servers in de Verenigde Staten. <br />
                </div>
                <div className={'cookie-section'}>
                    <button
                        onClick={() => {
                            accept();
                            this.closeBanner();
                        }}
                    >
                        Accepteren
                    </button>
                </div>
            </article>
        );
    }
}

export default MDCookieBanner;
