// Modules
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

// Assets
import gemeenteDesktop from '../../img/marketing/DGA-gemeente-700x700px.jpg';
import bouwplaatsDesktop from '../../img/marketing/DGA-bouwplaats-700x700px.jpg';
import algemeenDesktop from '../../img/marketing/DGA-generiek-700x700px.jpg';
import algemeenMobiel from '../../img/marketing/DGA-generiek.jpg';
import gemeenteMobiel from '../../img/marketing/DGA-gemeente.jpg';
import bouwplaatsMobiel from '../../img/marketing/DGA-bouwplaats.jpg';
import oranjeCirkel from '../../img/marketing/oranje cirkel.png';

// Styling
import './Algemeen.scss';
import './Algemeen.tablet.scss';
import './Algemeen.mobiel.scss';

// Utils
import Header from '../../components/Header';
import logoInruilSVG from '../../img/logo groene inruildeals.svg';
import logoInruilSVGMobile from '../../img/logo-groene-inruildeals.svg';

import Footer from '../../components/Footer';
import MadeBy from '../../components/MadeBy';
import { MarketingModal } from './MarketingModal';
import ModelSliderMarketing from './ModelSliderMarketing';

class Algemeen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
            modalPortfolioVisible: false,
        };
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    onBannerClick = () => {
        if (window.innerWidth > 749) {
            this.setState({ modalPortfolioVisible: true });
        }
    };

    render() {
        const { routeProps } = this.props;
        const { path } = routeProps.match;

        let doelgroep = 'Algemeen';

        // Algemeen
        let imageSrc = this.state.width <= 950 ? algemeenMobiel : algemeenDesktop;

        let introText =
            'Een CO2-reductie van meer dan 90%? Dat is mogelijk met de DGA modellen van De Groene Aggregaat. Wij bieden onze klanten al 7 jaar(!) de mogelijkheid om de co2 en stikstof uitstoot drastisch te verminderen en de operationele kosten van brandstofverbruik te minimaliseren.';

        // Gemeente
        if (path === '/gemeente/') {
            imageSrc = this.state.width <= 950 ? gemeenteMobiel : gemeenteDesktop;
            doelgroep = 'Gemeente';
            introText =
                'Een CO2-reductie van meer dan 90%? Dat is mogelijk met de DGA modellen van De Groene Aggregaat. Wij bieden onze klanten al 7 jaar(!) de mogelijkheid om de co2 en stikstof uitstoot drastisch te verminderen en de operationele kosten van brandstofverbruik te minimaliseren.';

        }

        // Bouwplaats
        if (path === '/bouw-en-infra/') {
            imageSrc = this.state.width <= 950 ? bouwplaatsMobiel : bouwplaatsDesktop;
            doelgroep = 'Bouw en Infra';
            introText =
                'Een CO2-reductie van meer dan 90%? Dat is mogelijk met de DGA modellen van De Groene Aggregaat. Wij bieden onze klanten al 7 jaar(!) de mogelijkheid om de co2 en stikstof uitstoot drastisch te verminderen en de operationele kosten van brandstofverbruik te minimaliseren.';

        }

        return (
            <>
                <Helmet>
                    <title>{doelgroep} | De Groene Aggregaat Verhuur</title>
                    <meta name="description" content="De Groene Aggregaat staat voor u klaar!" />
                </Helmet>

                <Header isMarketing={true} />
                <main className="page-marketing">
                    <MarketingModal
                        onThanks={this.props.navigateToBedankt}
                        doelgroep={doelgroep}
                        visibility={this.state.modalPortfolioVisible}
                        onClick={() => this.setState({ modalPortfolioVisible: !this.state.modalPortfolioVisible })}
                    />

                    <section className="marketing-page">
                        <h2 className={'small-screen-visible'}>HUUR DE ZEKERHEID VAN ALTIJD GROENE ENERGIE</h2>

                        <div className={'marketing-tiles'}>
                            <div className={'marketing-tile'}>
                                <div className={'white-block'}>
                                    <h2 className={'small-screen-hidden'}>HUUR DE ZEKERHEID VAN ALTIJD GROENE ENERGIE</h2>
                                    <p>
                                        {introText}
                                        <br />
                                        <br />
                                        Meer weten over de verschillende modellen, download onze brochure via onderstaande button.
                                    </p>
                                    <button onClick={() => this.setState({ modalPortfolioVisible: true })}>Download brochure</button>
                                </div>
                                <div className={'green-block'}>
                                    <h2>Waarom de groene aggregaat?</h2>
                                    <ul>
                                        <li>Door EU erkend energiesysteem (Seal of Excellence 879438)</li>
                                        <li>7 jaar ervaring met geïntegreerde energiesystemen</li>
                                        <li>CO2-reductie van meer dan 90%</li>
                                        <li>Eenvoudig te plaatsen, binnen twee minuten operationeel</li>
                                        <li>Volledig automatisch incl. de zonnepaneel</li>
                                        {/*}<li>Financial lease - nu gebruiken, gespreid betalen (60 mnd.)</li>{*/}
                                    </ul>
                                </div>
                            </div>

                            <div className={'marketing-tile'}>
                                <img src={imageSrc} alt={'Marketing'} />
                                <img className={'orange-circle'} src={oranjeCirkel} alt={'Al vanaf 305 euro per week!'} />
                            </div>
                        </div>
                    </section>

                    <section className="explore explore--marketing">
                        <div className="explore__wrapper">
                            <h2 className="explore__heading-desktop">Ontdek onze groene aggregaat</h2>
                            <p className="explore__subheading">De mobiele energiebesparing</p>
                            <ModelSliderMarketing navigateToModelBedankt={this.props.navigateToModelBedankt} doelgroep={doelgroep} />
                        </div>
                    </section>

                    <div className="exchange" onClick={this.onBannerClick}>
                        <div className="exchange__wrapper">
                            <img className="exchange__logo exchange-desktop-only" src={logoInruilSVG} width="356" height="113" alt="De Groene Inruildeals logo" />
                            <img className="exchange__logo exchange-mobile-only" src={logoInruilSVGMobile} width="356" height="113" alt="De Groene Inruildeals logo" />
                            <div className="exchange__description-wrapper">
                                <p className="exchange__description exchange__description--first">WIJ - UW VERVUILENDE AGGREGAAT.</p>
                                <p className="exchange__description exchange__description--second">
                                    <span style={{ color: '#525251' }}>U - INRUILKORTING OPEEN GROENE AGGREGAAT.</span>
                                    <br />
                                    INTERESSE? VRAAG AAN!
                                </p>
                                <p className="exchange__description exchange__description--mobile">
                                    U - INRUILKORTING OPEEN GROENE AGGREGAAT. <br />
                                    <span style={{ marginTop: '16px', display: 'block' }}>INTERESSE?</span>
                                </p>
                            </div>
                            <a className="exchange__link" href={'#'} onClick={e => e.preventDefault()} aria-label="Naar de groene inruildeals" />
                            <div onClick={() => this.setState({ modalPortfolioVisible: true })} className="exchange__link-mobile">
                                VRAAG AAN
                            </div>
                        </div>
                    </div>
                </main>

                <Footer />
                <MadeBy />
            </>
        );
    }
}

function mapStateToProps(state, props) {
    return { routeProps: props };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    navigateToGreenDealsAction: index => {
        ownProps.history.push('/green-deals');
    },
    navigateToBedankt: index => {
        ownProps.history.push(ownProps.location.pathname + '?form=completed');
    },
    navigateToModelBedankt: (model, doelgroep) => {
        ownProps.history.push(ownProps.location.pathname + `?form=completed&model=${model}&type=${doelgroep}`);
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Algemeen);
