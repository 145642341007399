// Modules
import React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import factsheet from '../../factsheet-hybride-groene-aggregaat-verhuur.pdf';

// Images

// Model 5
import eenvoudig5 from '../../img/model5/model-5-de-groene-aggregaat-hybride-off-grid-energie-oplossing-eenvoudig-te-plaatsen.jpg';
import bediening5 from '../../img/model5/model-5-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-te-bedienen.jpg';
import aansluiting5 from '../../img/model5/model-5-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-aan-te-sluiten.jpg';

// Model 30
import eenvoudig30 from '../../img/model30/model-30-de-groene-aggregaat-hybride-off-grid-energie-oplossing-eenvoudig-te-plaatsen.jpg';
import installatie30 from '../../img/model30/model-30-de-groene-aggregaat-hybride-off-grid-energie-oplossing-automatische-installatie.jpg';
import bediening30 from '../../img/model30/model-30-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-te-bedienen.jpg';
import aansluiting30 from '../../img/model30/model-30-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-aan-te-sluiten.jpg';
import actief30 from '../../img/model30/model-30-de-groene-aggregaat-hybride-off-grid-energie-oplossing-binnen-2-minuten-actief.jpg';

// Model 45
import eenvoudig45 from '../../img/model45/model-45-de-groene-aggregaat-hybride-off-grid-energie-oplossing-eenvoudig-te-plaatsen.jpg';
import installatie45 from '../../img/model45/model-45-de-groene-aggregaat-hybride-off-grid-energie-oplossing-automatische-installatie.jpg';
import bediening45 from '../../img/model45/model-45-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-te-bedienen.jpg';
import aansluiting45 from '../../img/model45/model-45-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-aan-te-sluiten.jpg';
import actief45 from '../../img/model45/model-45-de-groene-aggregaat-hybride-off-grid-energie-oplossing-binnen-2-minuten-actief.jpg';

// Model 100
import eenvoudig100 from '../../img/model100/model-100-de-groene-aggregaat-hybride-off-grid-energie-oplossing-eenvoudig-te-plaatsen-2.jpg';
import bediening100 from '../../img/model100/model-100-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-te-bedienen.jpg';
import aansluiting100 from '../../img/model100/model-100-de-groene-aggregaat-hybride-off-grid-energie-oplossing-gemakkelijk-aan-te-sluiten.jpg';
import actief100 from '../../img/model100/model-100-de-groene-aggregaat-hybride-off-grid-energie-oplossing-binnen-2-minuten-actief-2.jpg';

// Maatwerk
import maatwerk2 from '../../img/maatwerk/maatwerk2.jpg';
import maatwerk3 from '../../img/maatwerk/maatwerk3.jpg';
import maatwerk5 from '../../img/maatwerk/maatwerk5.jpg';
import maatwerk6 from '../../img/maatwerk/maatwerk6.jpg';
import maatwerk7 from '../../img/maatwerk/maatwerk7.jpg';

// Styling
import './Models.scss';

import Slick from '../../components/Slick';
import { connect } from 'react-redux';
import Footer from '../../components/Footer';
import Header, { MyModal } from '../../components/Header';
import { Helmet } from 'react-helmet-async';
import ScrollToTop from '../../components/ScrollToTop';
import MadeBy from '../../components/MadeBy';

class Models extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentShowcaseItem: 0,
            readMore: false,
            visibility: false,
        };
    }

    setVisibility = value => {
        this.setState({ visibility: value });
    };

    updateShowcase = () => {
        const nextItem = this.state.currentShowcaseItem + 1;

        const numLabels = getModelInfo(this.props.model, 'labels').length - 1;

        this.setState({
            currentShowcaseItem: nextItem > numLabels ? 0 : nextItem,
        });
    };

    componentDidMount() {
        this.interval = setInterval(this.updateShowcase, 4000);
    }

    stopInterval(model) {
        clearInterval(this.interval);
        this.setState({ currentShowcaseItem: model || 0 });
    }

    resetInterval() {
        clearInterval(this.interval);
        this.setState({ currentShowcaseItem: 0 });
        this.interval = setInterval(this.updateShowcase, 4000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { model, navigateToAction } = this.props;
        const { currentShowcaseItem } = this.state;

        const labels = getModelInfo(model, 'labels');
        let initialSlide;
        switch (model) {
            case '5':
                initialSlide = 4;
                break;
            case '30':
                initialSlide = 0;
                break;
            case '45':
                initialSlide = 1;
                break;
            case '100':
                initialSlide = 2;
                break;
            case 'maatwerk':
                initialSlide = 3;
                break;
            default:
                initialSlide = 0;
                break;
        }

        return (
            <>
                {getModelInfo(model, 'meta')}
                <Header />
                <MyModal
                    showGreenTile={false}
                    title={'Ik help u graag verder met ' + getModelInfo(model, 'name')}
                    visibility={this.state.visibility}
                    salesImage={true}
                    onClick={() => this.setVisibility(!this.state.visibility)}
                />
                <main className={cx('page-main', { maatwerk: model === 'maatwerk' })}>
                    <section className={'model-list-menu not-on-mobile'}>
                        <a
                            onClick={() => {
                                this.resetInterval();
                                navigateToAction(4);
                            }}
                            className={cx({ 'active-model': model === '5' })}
                        >
                            Model 5
                        </a>
                        <a
                            onClick={() => {
                                this.resetInterval();
                                navigateToAction(0);
                            }}
                            className={cx({ 'active-model': model === '30' })}
                        >
                            Model 30
                        </a>
                        <a
                            onClick={() => {
                                this.resetInterval();
                                navigateToAction(1);
                            }}
                            className={cx({ 'active-model': model === '45' })}
                        >
                            Model 45
                        </a>
                        <a
                            onClick={() => {
                                this.resetInterval();
                                navigateToAction(2);
                            }}
                            className={cx({ 'active-model': model === '100' })}
                        >
                            Model 100
                        </a>
                        <a
                            onClick={() => {
                                this.resetInterval();
                                navigateToAction(3);
                            }}
                            className={cx({ 'active-model': model === 'maatwerk' })}
                        >
                            Maatwerk
                        </a>
                    </section>
                    <section className={'model-list-menu not-on-desktop'}>
                        <Slick
                            initialSlide={initialSlide}
                            onAfterChange={index => {
                                this.resetInterval();
                                navigateToAction(index);
                            }}
                        />
                    </section>

                    <div className={'model-page-container'}>
                        <div className={'info-block'}>
                            <h2>{getModelInfo(model, 'title')}</h2>
                            <p>{getModelInfo(model, 'info_1')}</p>
                            <p>{getModelInfo(model, 'info_2')}</p>
                            <div className={'contact-info'}>
                                <h2> {model === 'maatwerk' ? 'Uw Groene Aggregaat op maat?' : 'Deze aggregaat huren?'}</h2>
                                <p>{model === 'maatwerk' ? 'Wij bouwen hem!' : 'Of interesse in koop?'}</p>
                                <div className="comparison__contact-container--desktop">
                                    <div className="comparison__button contact-button contact-button--phone site-button" onClick={() => window.open('tel:+31299416641')}>
                                        <a className="site-link" href="tel:+31299416641" alt="Bel met de groene aggregaat" rel="noopener">
                                            +31 299 - 416641
                                        </a>
                                    </div>
                                    <div className="comparison__button contact-button contact-button--mail site-button" onClick={() => this.setVisibility(!this.state.visibility)}>
                                        <a className="site-link" alt="Stuur een bericht naar de groene aggregaat" rel="noopener">
                                            Stuur een bericht
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'showcase-block'}>
                            <div className={'desktop-labels'}>
                                <div className={cx('showcase-label', { 'active-label': currentShowcaseItem === 0 })} onClick={() => this.stopInterval(0)}>
                                    <div>{labels[0]}</div>
                                </div>
                                <div className={cx('showcase-label', { 'active-label': currentShowcaseItem === 1 })} onClick={() => this.stopInterval(1)}>
                                    <div>{labels[1]}</div>
                                </div>
                                <div className={cx('showcase-label', { 'active-label': currentShowcaseItem === 2 })} onClick={() => this.stopInterval(2)}>
                                    <div>{labels[2]}</div>
                                </div>
                                {labels[3] && (
                                    <div className={cx('showcase-label', { 'active-label': currentShowcaseItem === 3 })} onClick={() => this.stopInterval(3)}>
                                        <div>{labels[3]}</div>
                                    </div>
                                )}
                                {labels[4] && (
                                    <div className={cx('showcase-label', { 'active-label': currentShowcaseItem === 4 })} onClick={() => this.stopInterval(4)}>
                                        <div>{labels[4]}</div>
                                    </div>
                                )}
                            </div>

                            <div className={'showcase-image-container'}>
                                <img className={'showcase-image'} alt={'model'} src={getModelInfo(model, 'images')[currentShowcaseItem]} />

                                <div className={'mobile-showcase-labels'}>
                                    <div className={'mobile-label'}>{labels[currentShowcaseItem]}</div>
                                    <div className={'mobile-label-dots'}>
                                        {labels.map((label, key) => (
                                            <div key={key} className={key === currentShowcaseItem ? 'active' : ''} />
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className={'mobile-info-block'}>
                                <div className={this.state.readMore ? 'animate-readmore' : 'animate-readless'}>
                                    <p>{getModelInfo(model, 'info_1')}</p>
                                    <p>{getModelInfo(model, 'info_2')}</p>
                                </div>

                                <button className={'read-more'} onClick={() => this.setState({ readMore: !this.state.readMore })}>
                                    Lees {!this.state.readMore ? 'meer' : 'minder'}
                                </button>

                                <p className={'interesse'}>{model === 'maatwerk' ? 'Uw Groene Aggregaat op maat?' : 'Interesse in deze aggregaat?'}</p>

                                <div className={'model-contact-buttons'}>
                                    <a
                                        style={{ border: '3px solid #009f3c' }}
                                        className="team-member__contact-mobile contact-button-mobile contact-button-mobile--phone"
                                        href="tel:+31299416641"
                                        alt="Bel met de groene aggregaat"
                                    >
                                        Bel ons
                                    </a>
                                    <a
                                        style={{ border: '3px solid #009f3c' }}
                                        className="team-member__contact-mobile contact-button-mobile contact-button-mobile--mail"
                                        alt="Stuur een bericht naar de groene aggragaat"
                                        onClick={() => this.setVisibility(!this.state.visibility)}
                                    >
                                        Bericht
                                    </a>
                                </div>
                            </div>

                            <div className={'model-specs'}>
                                <div className={'facts'}>
                                    <h2>
                                        {getModelInfo(model, 'name')} Specificaties{' '}
                                        <a className="download-button-factsheet" href={factsheet} alt="Download een pdf met alle gegevens" target={'_blank'} download>
                                            <i className={'material-icons'}>cloud_download</i>
                                            <div className={'specs-download'}>Specs</div>
                                        </a>{' '}
                                    </h2>
                                    <div className={'left-specs'}>
                                        <span>- {getModelInfo(model, 'specs')[0]}</span>
                                        <span>- {getModelInfo(model, 'specs')[1]}</span>
                                        <span>- {getModelInfo(model, 'specs')[2]}</span>
                                    </div>
                                    <div className={'right-specs'}>
                                        <span>- {getModelInfo(model, 'specs')[3]}</span>
                                        <span>- {getModelInfo(model, 'specs')[4]}</span>
                                        <span>- {getModelInfo(model, 'specs')[5]}</span>
                                    </div>
                                </div>

                                <div className={'weekly-savings'}>
                                    <h3>Mogelijke besparing</h3>
                                    <p>Op jaarbasis:</p>
                                    <p>Brandstof: {getModelInfo(model, 'fuel')}</p>
                                    <p>CO2 Reductie: {getModelInfo(model, 'co2')}</p>
                                </div>
                            </div>

                            <div className={'mobile-model-specs'}>
                                <h2>{getModelInfo(model, 'name')} Specificaties </h2>
                                <div>
                                    <span>{getModelInfo(model, 'specs')[0]}</span>
                                    <span>{getModelInfo(model, 'specs')[1]}</span>
                                    <span>{getModelInfo(model, 'specs')[2]}</span>
                                    <span>{getModelInfo(model, 'specs')[3]}</span>
                                    <span>{getModelInfo(model, 'specs')[4]}</span>
                                    <span>{getModelInfo(model, 'specs')[5]}</span>
                                </div>
                                <h3>Mogelijke besparing</h3>
                                <p>Op jaarbasis:</p>
                                <p>Brandstof: {getModelInfo(model, 'fuel')}</p>
                                <p>CO2 Reductie: {getModelInfo(model, 'co2')}</p>
                                <a className={'download-button-factsheet'} href={factsheet} alt="Download een pdf met alle gegevens" target={'_blank'} download>
                                    <i className={'material-icons'}>cloud_download</i>
                                    <div className={'specs-download'}>Factsheet</div>
                                </a>{' '}
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
                <MadeBy />
            </>
        );
    }
}

function mapStateToProps(state, props) {
    return { routeProps: props };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    navigateToAction: index => {
        let url = '/';
        // switch the index intpo the currect model number -->
        switch (index) {
            case 4:
                url = '/model-5';
                break;
            case 0:
                url = '/model-30';
                break;
            case 1:
                url = '/model-45';
                break;
            case 2:
                url = '/model-100';
                break;
            case 3:
                url = '/maatwerk';
                break;
            default:
                break;
        }
        console.log('probeer te navigeren naar model --> ', index, ownProps);
        ownProps.history.push(url);
    },
});

const mergeProps = (stateProps, dispatchProps, ownprops) => {
    console.log('stateProps --> ', stateProps);
    let model = stateProps.routeProps.match.path.split('/model-')[1];
    if (!model) {
        model = 'maatwerk';
    }
    return {
        ...stateProps,
        ...dispatchProps,
        model,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Models);

function getModelInfo(modelId, prop) {
    const modelsInfo = {
        '5': {
            name: 'MODEL 5',
            title: 'MODEL 5',
            info_1:
                'Ons kleinste model bewijst dat je ook zonder zonnepanelen milieubesparend kunt zijn. Met zijn kleine container van 8 ft is het een snelle en praktisch inzetbare hybride aggregaat van 5kVA voor iedere gelegenheid.',
            info_2:
                'Interesse om deze aggregaat te huren? Neem voor actuele beschikbaarheid en prijzen contact met ons op. Bij de huur kunnen wij desgewenst een tankservice leveren, waarmee we u compleet kunnen ontzorgen. Wij zullen dan op de juiste momenten de aggregaat van brandstof voorzien. Daarnaast hebben wij een service dienst welke 24/7 bereikbaar is. Kennis van de aggregaat is daardoor niet nodig, want u kunt ons altijd bereiken voor vragen of storingen.',
            labels: ['EENVOUDIG TE PLAATSEN', 'GEMAKKELIJK TE BEDIENEN', 'SIMPEL AAN TE SLUITEN'],
            images: [eenvoudig5, bediening5, aansluiting5],
            specs: [
                '8 ft container',
                '11 kVA generator',
                '5 kVA omvormer',
                'Aggregaat met een 3 fase aansluiting',
                'Aggregaat exlusief zonnepanelen',
                'Batterijopslag 15kWh Lithium & 28kWh OLZ',
            ],
            fuel: '€ 29.614,-',
            co2: '82.008 KG',
            meta: (
                <Helmet>
                    <title>Model 5 | De Groene Aggregaat Verhuur</title>
                    <meta charSet="utf-8" />
                    <meta
                        name="description"
                        content="Ons kleinste model bewijst dat je ook zonder zonnepanelen milieubesparend kunt zijn. Met zijn kleine container van 8 ft is het een snelle en praktisch inzetbare hybride aggregaat van 5kVA voor iedere gelegenheid"
                    />
                </Helmet>
            ),
        },
        '30': {
            name: 'MODEL 30',
            title: 'MODEL 30',
            info_1:
                'Ons instapmodel met geluidsarme aggregaat en zonnepanelen. Deze compacte hybride, groene krachtpatser van 30kVA is door zijn 10 ft container gemakkelijk te plaatsen op plekken met geringe ruimte.',
            info_2:
                'Interesse om deze aggregaat te huren? Neem voor actuele beschikbaarheid en prijzen contact met ons op. Bij de huur kunnen wij desgewenst een tankservice leveren, waarmee we u compleet kunnen ontzorgen. Wij zullen dan op de juiste momenten de aggregaat van brandstof voorzien. Daarnaast hebben wij een service dienst welke 24/7 bereikbaar is. Kennis van de aggregaat is daardoor niet nodig, want u kunt ons altijd bereiken voor vragen of storingen.',
            labels: ['EENVOUDIG TE PLAATSEN', 'AUTOMATISCHE INSTALLATIE', 'GEMAKKELIJK TE BEDIENEN', 'SIMPEL AAN TE SLUITEN', 'BINNEN 2 MINUTEN ACTIEF'],
            images: [eenvoudig30, installatie30, bediening30, aansluiting30, actief30],
            specs: [
                '10ft container',
                '45 kVA generator',
                '30 kVA omvormer',
                'Aggregaat met een 3 fase aansluiting',
                '6 zonnepanelen op roterende arm',
                'Batterijopslag 30kWh lithium',
            ],
            fuel: '€ 49.371,-',
            co2: '136.720 KG',
            meta: (
                <Helmet>
                    <title>Model 30 | De Groene Aggregaat Verhuur</title>
                    <meta charSet="utf-8" />
                    <meta
                        name="description"
                        content="Ons instapmodel met geluidsarme aggregaat en zonnepanelen. Deze compacte hybride, groene krachtpatser van 30kVA is door zijn 10 ft container gemakkelijk te plaatsen op plekken met geringe ruimte."
                    />
                </Helmet>
            ),
        },
        '45': {
            name: 'MODEL 45',
            title: 'MODEL 45',
            info_1:
                'Met 45kVA heeft deze hybride aggregaat meer vermogen dan Model 30. En dat terwijl dezelfde 10 ft container behouden blijft. Voor wie meer vermogen vereist binnen geringe ruimte.',
            info_2:
                'Interesse om deze aggregaat te huren? Neem voor actuele beschikbaarheid en prijzen contact met ons op. Bij de huur kunnen wij desgewenst een tankservice leveren, waarmee we u compleet kunnen ontzorgen. Wij zullen dan op de juiste momenten de aggregaat van brandstof voorzien. Daarnaast hebben wij een service dienst welke 24/7 bereikbaar is. Kennis van de aggregaat is daardoor niet nodig, want u kunt ons altijd bereiken voor vragen of storingen.',
            labels: ['EENVOUDIG TE PLAATSEN', 'AUTOMATISCHE INSTALLATIE', 'GEMAKKELIJK TE BEDIENEN', 'SIMPEL AAN TE SLUITEN', 'BINNEN 2 MINUTEN ACTIEF'],
            images: [eenvoudig45, installatie45, bediening45, aansluiting45, actief45],
            specs: [
                '10ft container',
                '60 kVA generator',
                '45 kVA omvormer',
                'Aggregaat met een 3 fase aansluiting',
                '6 zonnepanelen op roterende arm',
                'Batterijopslag 30kWh lithium',
            ],
            fuel: '€ 79.039,-',
            co2: '218.878 KG',
            meta: (
                <Helmet>
                    <title>Model 45 | De Groene Aggregaat Verhuur</title>
                    <meta charSet="utf-8" />
                    <meta
                        name="description"
                        content="Met 45kVA heeft deze hybride aggregaat meer vermogen dan Model 30. En dat terwijl dezelfde 10 ft container behouden blijft. Voor wie meer vermogen vereist binnen geringe ruimte."
                    />
                </Helmet>
            ),
        },
        '100': {
            name: 'MODEL 100',
            title: 'MODEL 100',
            info_1:
                'Door zijn 100kVA is onze grote hybride aggregaat een alleskunner voor het zwaardere werk. Met zijn container van 20 ft is er meer plaats voor zonnepanelen, waardoor het rendement ook nog eens beter uitvalt.',
            info_2:
                'Interesse om deze aggregaat te huren? Neem voor actuele beschikbaarheid en prijzen contact met ons op. Bij de huur kunnen wij desgewenst een tankservice leveren, waarmee we u compleet kunnen ontzorgen. Wij zullen dan op de juiste momenten de aggregaat van brandstof voorzien. Daarnaast hebben wij een service dienst welke 24/7 bereikbaar is. Kennis van de aggregaat is daardoor niet nodig, want u kunt ons altijd bereiken voor vragen of storingen.',
            labels: ['EENVOUDIG TE PLAATSEN', 'GEMAKKELIJK TE BEDIENEN', 'SIMPEL AAN TE SLUITEN', 'BINNEN 2 MINUTEN ACTIEF'],
            images: [eenvoudig100, bediening100, aansluiting100, actief100],
            specs: [
                '20ft container',
                '100 kVA generator',
                '100 kVA omvormer',
                'Aggregaat met een 3 fase aansluiting',
                '20 zonnepanelen op roterende arm',
                'Batterijopslag 110kWh lithium',
            ],
            fuel: '€ 217.872,-',
            co2: '603.338 KG',
            meta: (
                <Helmet>
                    <title>Model 100 | De Groene Aggregaat Verhuur</title>
                    <meta charSet="utf-8" />
                    <meta
                        name="description"
                        content="Door zijn 100kVA is onze grote hybride aggregaat een alleskunner voor het zwaardere werk. Met zijn container van 20 ft is er meer plaats voor zonnepanelen, waardoor het rendement ook nog eens beter uitvalt."
                    />
                </Helmet>
            ),
        },
        maatwerk: {
            name: 'MAATWERK',
            title: 'MAATWERK',
            info_1:
                'Wij kunnen de Groene Aggregaat ook compleet op maat voor u ontwikkelen. Als one-stop-shop huisvesten wij alle noodzakelijke diensten om het ontwikkelproces nauwkeurig te laten verlopen. Het gehele proces verloopt binnen onze eigen organisatie, waardoor u nauw betrokken bent bij de ontwikkeling van uw eigen product.',
            info_2:
                'We hebben reeds verschillende modules ontwikkeld, zoals bijvoorbeeld een 4G mast, schaftkeet of waterzuivering. Maar uiteraard ook een container met eigen bedrijfskleur, logo en specifieke afmetingen behoren te de mogelijkheden. Schroom niet ons te contacten voor een specifieke wens. Wij deinzen niet terug voor een uitdagend project.',
            labels: ['RESEARCH & DEVELOPMENT', 'SOFTWARE OP MAAT', 'EIGEN WERKPLAATS', 'EXTRA MODULES', 'ONBEPERKTE MOGELIJKHEDEN'],
            images: [maatwerk7, maatwerk5, maatwerk6, maatwerk2, maatwerk3],
            specs: [
                'Conform specifieke normen',
                'Generator voor iedere krachtmeting',
                'Omvormer naar keuze',
                'Persoonlijk ontwerp',
                'Maatwerk modules',
                'Afgestemde hybride pakketten',
            ],
            fuel: 'op maat',
            co2: 'op maat',
            meta: (
                <Helmet>
                    <title>Maatwerk | De Groene Aggregaat Verhuur</title>
                    <meta charSet="utf-8" />
                    <meta
                        name="description"
                        content="Wij kunnen de Groene Aggregaat ook compleet op maat voor u ontwikkelen. Als one-stop-shop huisvesten wij alle noodzakelijke diensten om het ontwikkelproces nauwkeurig te laten verlopen. Het gehele proces verloopt binnen onze eigen organisatie, waardoor u nauw betrokken bent bij de ontwikkeling van uw eigen product."
                    />
                </Helmet>
            ),
        },
    };

    return modelsInfo[modelId][prop];
}
