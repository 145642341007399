// Modules
import React from 'react';

// Images
import dgaLogoSVG from '../../img/dga-logo.svg';

// Styling
import './contact.scss';
import './contact-mobile.scss';
import './team.scss';
import './team-mobile.scss';
import { Helmet } from 'react-helmet-async';

const Contact = () => (
    <main className="page-main">
        <Helmet>
            <title>Contact | De Groene Aggregaat Verhuur</title>
            <meta name="description" content="Ons team staat voor u klaar!" />
        </Helmet>
        <h1 className="visually-hidden">Contact</h1>
        <section className="team">
            <div className="team__wrapper">
                <b className="team__direction">U kunt ons vinden in de Zuidoostbeemster, naast de A7</b>
                <div className="contact">
                    <div className="contact__address-container">
                        <h3 className="contact__heading">Bezoekadres:</h3>
                        <p className="contact__address">
                            Vredenburghweg 2<br />
                            1461 GT Zuidoostbeemster, Nederland
                        </p>
                        <p className="contact__address">
                            <b>Afdeling verhuur</b>
                            <br />
                            <br />
                            +31 (0) 299 – 41 66 41
                            <br />
                            verhuur@degroeneaggregaat.nl
                        </p>
                        <p className="contact__address">
                            <b>
                            Overgaan tot aankoop?
                            <br />
                            Neem direct contact op met onze producent MARU via:
                            </b>
                            <br />
                            <br />
                            +31 (0) 299 - 41 63 03
                            <br />
                            sales@maru.energy
                            <br />
                            www.maru.energy
                        </p>
                    </div>
                    <img className="contact__logo" src={dgaLogoSVG} width="600" height="224" alt="De groene aggregaat logo" />
                </div>
            </div>

            <div className="contact__photo" />
        </section>
    </main>
);

export default Contact;
