import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import ReactGA from 'react-ga';

// Reducers
import { reducers } from './reducers';

// Saga
import { rootSaga } from './sagas';

// import logo from "./logo.svg";
import './App.scss';

// Components
import Page from './components/Page';
import Home from './routes/home/Home';
import Contact from './routes/contact/Contact';
import GreenDeals from './routes/green-deals/GreenDeals';
import Models from './routes/model/Models';
import ScrollToTop from './components/ScrollToTop';
import OnzeVisie from './routes/onze-visie/OnzeVisie';
import PageNotFound from './routes/404/PageNotFound';
import InActie from './routes/in-actie/InActie';
import MDCookieBanner from './components/MDCookieBanner';
import Algemeen from './routes/algemeen/Algemeen';

import TagManager from 'react-gtm-module';

const GoogleAnalytics = (WrappedComponent) => (props) => {
    if (!!document.cookie) {
        ReactGA.pageview(window.location.pathname);
    }

    return <WrappedComponent {...props} />;
};

function App() {
    // 1) Create and Apply the redux saga Middlewares
    const sagaMiddleware = createSagaMiddleware();
    // const _routerMiddleware = routerMiddleware();

    // 2) Create the initial redux state of the application
    const initialState = {};

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    // 3) Create the Redux Store and apply the saga middleware to it
    const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));

    // 4) Start the root saga
    sagaMiddleware.run(rootSaga);

    // Initialize Google Tag Manager
    const tagManagerArgs = {
        gtmId: 'GTM-WP835SB',
    };
    TagManager.initialize(tagManagerArgs);

    return (
        <HelmetProvider>
            <Provider store={store}>
                <BrowserRouter>
                    <ScrollToTop>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>Huur een hybride aggregaat | De Groene Aggregaat Verhuur</title>
                            <meta name="description" content="Huur De Groene Aggregaat en bespaar tot 95% op het milieu én uw portmonnee!" />
                        </Helmet>
                        <Switch>
                            <Route exact path={'/'} component={GoogleAnalytics(Home)} />
                            <Route path={'/contact'} component={GoogleAnalytics(Page(<Contact />))} />
                            <Route path={'/green-deals'} component={GoogleAnalytics(Page(<GreenDeals />))} />
                            <Route path={'/model-5'} component={GoogleAnalytics(Models)} />
                            <Route exact path={'/model-30'} component={GoogleAnalytics(Models)} />
                            <Route path={'/model-45'} component={GoogleAnalytics(Models)} />
                            <Route path={'/model-100'} component={GoogleAnalytics(Models)} />
                            <Route path={'/maatwerk'} component={GoogleAnalytics(Models)} />
                            <Route path={'/onze-visie'} component={GoogleAnalytics(Page(<OnzeVisie />))} />

                            <Route path={'/algemeen/'} component={GoogleAnalytics(Algemeen)} />
                            <Route path={'/gemeente/'} component={GoogleAnalytics(Algemeen)} />
                            <Route path={'/bouw-en-infra/'} component={GoogleAnalytics(Algemeen)} />

                            <Route path={'/in-actie/hybride-groene-aggregaat-bij-noord-zuid-lijn'} component={GoogleAnalytics(InActie)} />
                            <Route path={'/in-actie/van-hattum-huurt-de-groene-aggregaat'} component={GoogleAnalytics(InActie)} />
                            <Route path={'/in-actie/boels-verhuurt-de-hybride-groene-aggregaat'} component={GoogleAnalytics(InActie)} />
                            <Route path={'/in-actie/de-groene-aggregaat-bij-defensie'} component={GoogleAnalytics(InActie)} />
                            <Route path={'/in-actie/de-groene-aggregaat-op-schiphol'} component={GoogleAnalytics(InActie)} />
                            <Route path={'/in-actie/huur-de-hybride-groene-aggregaat-voor-uw-evenement'} component={GoogleAnalytics(InActie)} />

                            <Redirect from="/in-actie" to="/in-actie/hybride-groene-aggregaat-bij-noord-zuid-lijn" />

                            <Route component={GoogleAnalytics(Page(<PageNotFound />))} />
                        </Switch>
                        <MDCookieBanner
                            accept={() => {
                                ReactGA.initialize('UA-169205233-1');
                                ReactGA.set({
                                    userId: '1',
                                    hovius: true,
                                    // any data that is relevant to the user session
                                    // that you would like to track with google analytics
                                });
                            }}
                        />
                    </ScrollToTop>
                </BrowserRouter>
            </Provider>
        </HelmetProvider>
    );
}

export default App;
