// Modules
import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { connect } from 'react-redux';
import Slider from 'react-slick';

// Images
import model5 from '../../img/home-models/model-5/huur-onze-groene-aggregaat-model-5-een-hybride-off-grid-stroom-oplossing-klein.png';
import model30 from '../../img/home-models/model-30/huur-onze-groene-aggregaat-model-30-de-hybride-besparende-aggregaat-met-zonnepanelen-klein.png';
import model45 from '../../img/home-models/model-45/huur-onze-groene-aggregaat-model-45-bespaar-op-milileu-en-brandstof-met-de-hybride-stroom-oplossing-klein.png';
import model100 from '../../img/home-models/model-100/huur-onze-groene-aggregaat-model-100-hybride-aggregaat-met-zonnepanelen-veel-vermogen-en-laag-co2-uitstoot-klein.png';

import logoInruilSVGMobile from '../../img/logo-groene-inruildeals.svg';
import logoInruilSVG from '../../img/logo groene inruildeals.svg';

// Components
import Footer from '../../components/Footer';
import Header, { MyModal } from '../../components/Header';
import { Desktop, Mobile, Tablet } from '../../components/Wrappers';
import { OfferteModal } from '../green-deals/GreenDeals';

// Styling
import './advantages-list.scss';
import './advantages-list-mobile.scss';
import './body.scss';
import './comparison.scss';
import './comparison-mobile.scss';
import './exchange.scss';
import './exchange-mobile.scss';
import './explore.scss';
import './explore-mobile.scss';
import './intro.scss';
import './intro-mobile.scss';
import './modal-question.scss';
import '../../components/Slick.scss';
import MadeBy from '../../components/MadeBy';

class Home extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            currentModelIndex: 2,
            modalVisible: false,
            visibility: false,
        };
    }

    setModal = () => {
        this.setState({ modalVisible: !this.state.modalVisible });
    };

    getModelInfoByIndex = (index, property) => {
        return {
            '0': {
                name: 'Model 5',
                route: '/model-5',
                fuel: { min: '€5226', max: '€34.840' },
                co2: { min: '14.472kg', max: '96.480kg' },
                shortInfo:
                    'Ons kleinste model bewijst dat je ook zonder zonnepanelen milieubesparend kunt zijn. Met zijn kleine container van 8 ft is het een snel en praktisch inzetbare hybride aggregaat van 5kVA voor elke gelegenheid.',
            },
            '1': {
                name: 'Model 30',
                route: '/model-30',
                fuel: { min: '€8713', max: '€58.084' },
                co2: { min: '24.128kg', max: '160.848kg' },
                shortInfo:
                    'Ons instapmodel met geluidsarme aggregaat en zonnepanelen. Deze compacte hybride, groene krachtpatser van 30kVA is door zijn 10 ft container gemakkelijk te plaatsen op plekken met geringe ruimte.',
            },
            '2': {
                name: 'Model 45',
                route: '/model-45',
                fuel: { min: '€13.937', max: '€92.976' },
                co2: { min: '38.594kg', max: '257.472kg' },
                shortInfo:
                    'Met 45kVA heeft deze hybride aggregaat meer vermogen dan Model 30. En dat terwijl dezelfde 10 ft container behouden blijft. Voor wie een hybride aggregaat met meer vermogen vereist binnen geringe ruimte.',
            },
            '3': {
                name: 'Model 100',
                route: '/model-100',
                fuel: { min: '€37.760', max: '€255.632' },
                co2: { min: '104.566kg', max: '707.904kg' },
                shortInfo:
                    'Door zijn 100kVA is onze grote hybride aggregaat een alleskunner voor het zwaardere werk. Met zijn container van 20 ft is er meer plaats voor zonnepanelen, waardoor het rendement ook nog eens beter uitvalt.',
            },
        }[index][property];
    };

    onScrollToComparison = e => {
        // Get the element we want to scroll to form DOM
        const exploreElement = document.getElementsByClassName('explore')[0];

        if (exploreElement) {
            window.scrollTo({
                top: exploreElement.offsetTop - 20,
                behavior: 'smooth',
            });
        }
    };

    setActiveModel = currentModelIndex => {
        console.log('currentModelIndex --> ', currentModelIndex);
        this.setState({ currentModelIndex });

        const outerFuel = document.getElementsByClassName('comparison__bar--outer-fuel')[0];
        const innerFuel = document.getElementsByClassName('comparison__bar-inner--fuel')[0];
        const outerCO2 = document.getElementsByClassName('comparison__bar--outer-co2')[0];
        const innerCO2 = document.getElementsByClassName('comparison__bar-inner--co2')[0];

        if (outerFuel && innerFuel && outerCO2 && innerCO2) {
            if (outerFuel.classList.contains('animate')) {
                outerFuel.classList.remove('animate');
                innerFuel.classList.remove('animate');
                outerCO2.classList.remove('animate');
                innerCO2.classList.remove('animate');
            }

            setTimeout(() => {
                outerFuel.classList.add('animate');
                innerFuel.classList.add('animate');
                outerCO2.classList.add('animate');
                innerCO2.classList.add('animate');
            }, 1);
        }
    };

    updateDimensions = () => {
        if (window.innerWidth >= 750) {
            this.setState({ width: window.innerWidth, height: window.innerHeight });
        }
    };

    onScroll = () => {
        const element = document.getElementsByClassName('intro__next')[0];
        if (element) {
            element.style.opacity = 1 - window.scrollY / 250;
        }
        const outerFuel = document.getElementsByClassName('comparison__bar--outer-fuel')[0];
        const innerFuel = document.getElementsByClassName('comparison__bar-inner--fuel')[0];
        const outerCO2 = document.getElementsByClassName('comparison__bar--outer-co2')[0];
        const innerCO2 = document.getElementsByClassName('comparison__bar-inner--co2')[0];

        // Animate the progress bars
        if (window.scrollY > 610) {
            if (outerFuel && innerFuel && outerCO2 && innerCO2) {
                if (!outerFuel.classList.contains('animate')) {
                    outerFuel.classList.add('animate');
                    innerFuel.classList.add('animate');
                    outerCO2.classList.add('animate');
                    innerCO2.classList.add('animate');
                }
            }
        } else {
            if (outerFuel && innerFuel && outerCO2 && innerCO2) {
                if (outerFuel.classList.contains('animate')) {
                    outerFuel.classList.remove('animate');
                    innerFuel.classList.remove('animate');
                    outerCO2.classList.remove('animate');
                    innerCO2.classList.remove('animate');
                }
            }
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        console.log('ik unmount nu');
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.onScroll);
    }

    onBannerClick = () => {
        if (window.innerWidth > 749) {
            this.props.navigateToGreenDealsAction();
        }
    };

    setVisibility = value => {
        this.setState({ visibility: value });
    };

    render() {
        return (
            <>
                <Header />
                <MyModal
                    showGreenTile={false}
                    title={'Ik help u graag verder met ' + this.getModelInfoByIndex(this.state.currentModelIndex, 'name')}
                    visibility={this.state.visibility}
                    salesImage={true}
                    onClick={() => this.setVisibility(!this.state.visibility)}
                />
                <main className="page-main page-home">
                    <OfferteModal visibility={this.state.modalVisible} onClick={this.setModal} />

                    <h1 className="visually-hidden">De Groene Aggregaat</h1>
                    <div className="background-image" style={{ minHeight: this.state.height || window.innerHeight, height: this.state.height || window.innerHeight }} />
                    <section className="intro" style={{ minHeight: this.state.height || window.innerHeight, height: this.state.height || window.innerHeight }}>
                        <h2 className="intro__heading">Huur altijd en overal groene energie</h2>
                        <p className="intro__subheading">Dé hybride stroomvoorziening op locatie </p>
                        <h2 className="intro__heading-mobile">Ontdek</h2>
                        <button className="intro__next button-reset" type="button" aria-label="Verder lezen" onClick={this.onScrollToComparison} />
                    </section>

                    <section className="explore">
                        <div className="explore__wrapper">
                            <h2 className="explore__heading-desktop">Ontdek onze groene aggregaat</h2>
                            <p className="explore__subheading">De mobiele energiebesparing</p>

                            <Mobile>
                                <div className="container">
                                    <Slider
                                        slidesToShow={1}
                                        initialSlide={this.state.currentModelIndex}
                                        afterChange={args => {
                                            this.setActiveModel(args);
                                        }}
                                        onSwipe={() => console.log('Finger is not on screen anymore --> ')}
                                        {...{
                                            dots: false,
                                        }}
                                    >
                                        <div className={'model-image-wrapper model-5'}>
                                            <img src={model5} alt="Model 5" />
                                        </div>
                                        <div className={'model-image-wrapper model-30'}>
                                            <img src={model30} alt="Model 30" />
                                        </div>
                                        <div className={'model-image-wrapper model-45'}>
                                            <img src={model45} alt="Model 45" />
                                        </div>
                                        <div className={'model-image-wrapper model-100'}>
                                            <img src={model100} alt="Model 100" />
                                        </div>
                                    </Slider>
                                </div>
                                <h2 className="comparison__heading">{this.getModelInfoByIndex(this.state.currentModelIndex, 'name')}</h2>
                            </Mobile>

                            <Tablet>
                                <div className="container">
                                    <Slider
                                        slidesToShow={1}
                                        initialSlide={2}
                                        afterChange={args => {
                                            this.setActiveModel(args);
                                        }}
                                        onSwipe={() => console.log('Finger is not on screen anymore --> ')}
                                        {...{
                                            dots: false,
                                        }}
                                    >
                                        <div className={'model-image-wrapper model-5'}>
                                            <img src={model5} alt="Model 5" />
                                        </div>
                                        <div className={'model-image-wrapper model-30'}>
                                            <img src={model30} alt="Model 30" />
                                        </div>
                                        <div className={'model-image-wrapper model-45'}>
                                            <img src={model45} alt="Model 45" />
                                        </div>
                                        <div className={'model-image-wrapper model-100'}>
                                            <img src={model100} alt="Model 100" />
                                        </div>
                                    </Slider>
                                </div>
                                <h2 className="comparison__heading">{this.getModelInfoByIndex(this.state.currentModelIndex, 'name')}</h2>
                            </Tablet>

                            <Desktop>
                                <ul className="model-list site-list">
                                    <li
                                        className={cx('model-list__item', {
                                            'model-list__item--active': this.state.currentModelIndex === 0,
                                        })}
                                        onClick={() => this.setActiveModel(0)}
                                    >
                                        <img src={model5} style={{ width: '100px', height: 'auto' }} alt="Model 5" />
                                        <div className="model-list__wrapper">
                                            <a className="model-list__link site-link">Model 5</a>
                                        </div>
                                    </li>
                                    <li
                                        className={cx('model-list__item', {
                                            'model-list__item--active': this.state.currentModelIndex === 1,
                                        })}
                                        onClick={() => this.setActiveModel(1)}
                                    >
                                        <img className="model-list__image" src={model30} style={{ width: '200px', height: 'auto' }} alt="Model 30" />
                                        <div className="model-list__wrapper">
                                            <a className="model-list__link site-link">Model 30</a>
                                        </div>
                                    </li>
                                    <li
                                        className={cx('model-list__item', {
                                            'model-list__item--active': this.state.currentModelIndex === 2,
                                        })}
                                        onClick={() => this.setActiveModel(2)}
                                    >
                                        <img src={model45} style={{ width: '230px', height: 'auto' }} alt="Model 45" />
                                        <div className="model-list__wrapper">
                                            <a className="model-list__link site-link">Model 45</a>
                                        </div>
                                    </li>
                                    <li
                                        className={cx('model-list__item', {
                                            'model-list__item--active': this.state.currentModelIndex === 3,
                                        })}
                                        onClick={() => this.setActiveModel(3)}
                                    >
                                        <img src={model100} style={{ width: '430px', height: 'auto' }} alt="Model 100" />
                                        <div className="model-list__wrapper">
                                            <a className="model-list__link site-link">Model 100</a>
                                        </div>
                                    </li>
                                </ul>
                            </Desktop>

                            <div className="comparison">
                                <div className="comparison__model">
                                    <h3 className="comparison__heading comparison__heading--hidden">{this.getModelInfoByIndex(this.state.currentModelIndex, 'name')}</h3>
                                    <p className="comparison__description">{this.getModelInfoByIndex(this.state.currentModelIndex, 'shortInfo')}</p>
                                    <NavLink
                                        className="comparison__read-more comparison__read-more--desktop site-link"
                                        to={this.getModelInfoByIndex(this.state.currentModelIndex, 'route')}
                                    >
                                        Lees meer
                                    </NavLink>
                                </div>
                                <div className="comparison__graph">
                                    <div className="comparison__wrapper">
                                        <h3 className="comparison__heading comparison__heading--inline-block">Mogelijke besparing</h3>
                                        <NavLink
                                            className="comparison__read-more comparison__read-more--mobile  site-link"
                                            to={this.getModelInfoByIndex(this.state.currentModelIndex, 'route')}
                                        >
                                            Meer info
                                        </NavLink>
                                    </div>
                                    <div className={'colorful-text'}>
                                        <span className="comparison__versus comparison__versus--green">De Groene Aggregaat</span>
                                        <span className="comparison__versus comparison__versus--regular">versus</span>
                                        <span className="comparison__versus comparison__versus--red">Conventioneel</span>
                                    </div>

                                    {/*<br />*/}

                                    <div className="animation-box animation-box--top">
                                        <p className="comparison__title">Brandstofkosten per jaar</p>
                                        <div className="comparison__bar comparison__bar--outer-fuel">
                                            <div className="comparison__bar-inner comparison__bar-inner--fuel" />

                                            <div className="comparison__number-container comparison__number-container--fuel">
                                                <div className="comparison__number comparison__number--small">
                                                    {this.getModelInfoByIndex(this.state.currentModelIndex, 'fuel').min}
                                                </div>
                                                <div className="comparison__number comparison__number--big">
                                                    {this.getModelInfoByIndex(this.state.currentModelIndex, 'fuel').max}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*<br />*/}

                                    <div className="animation-box">
                                        <p className="comparison__title">CO2- uitstoot in kg per jaar</p>
                                        <div className="comparison__bar comparison__bar--outer-co2">
                                            <div className="comparison__bar-inner comparison__bar-inner--co2" />

                                            <div className="comparison__number-container comparison__number-container--co2">
                                                <div className="comparison__number comparison__number--small">
                                                    {this.getModelInfoByIndex(this.state.currentModelIndex, 'co2').min}
                                                </div>
                                                <div className="comparison__number comparison__number--big">
                                                    {this.getModelInfoByIndex(this.state.currentModelIndex, 'co2').max}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="rent">
                                    <h3 className="comparison__heading comparison__heading--rent">Deze aggregaat huren?</h3>
                                    <p className="comparison__subheading comparison__subheading--desktop">Of interesse in koop?</p>
                                    <h3 className="comparison__heading--mobile">Deze aggregaat huren of kopen?</h3>
                                    <div className="comparison__contact-container--desktop">
                                        <div className="comparison__button contact-button contact-button--phone site-button" onClick={() => window.open('tel:+31299416641')}>
                                            <a className="site-link" href="tel:+31299416641">
                                                +31 299 - 416641
                                            </a>
                                        </div>
                                        <div
                                            className="comparison__button contact-button contact-button--mail site-button"
                                            onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.setVisibility(!this.state.visibility);
                                            }}
                                        >
                                            Stuur een bericht
                                        </div>
                                    </div>
                                    <div className="comparison__contact-container--mobile">
                                        <div className="contact-button-mobile contact-button-mobile--phone site-button" onClick={() => window.open('tel:+31299416641')}>
                                            <a className="site-link" href="tel:+31299416641">
                                                Bel ons
                                            </a>
                                        </div>
                                        <div className="contact-button-mobile contact-button-mobile--mail site-button" onClick={() => this.setVisibility(!this.state.visibility)}>
                                            <a className="site-link">Bericht</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <section className="explore why-us">
                                <div className="explore__wrapper">
                                    <h2 className="explore__heading-desktop">WAAROM DE GROENE AGGREGAAT?</h2>
                                    <p className="explore__subheading about-us">
                                        In een tijd waar milieubesparing steeds belangrijker wordt, <br />
                                        is het ondenkbaar nog vervuilende, conventionele aggregaten te <br />
                                        gebruiken. De Groene Aggregaat biedt daarom een hybride <br />
                                        oplossing, waarin brandstof én zonnekracht samenkomen <br />
                                        om de CO2 uitstoot te reduceren.
                                    </p>
                                    <p className="explore__subheading about-us">
                                        Onze hybride aggregaat is zo besparend dat er een CO2 <br />
                                        reductie van meer dan 90% haalbaar is! <br />
                                        Dit alles door het significant mindere brandstofverbruik,
                                        <br />
                                        waardoor niet alleen het milieu, maar ook nog de <br /> portemonnee bespaard blijft.
                                    </p>
                                </div>
                            </section>

                            <ul className="advantages-list">
                                <li className="advantages-list__item advantages-list__item--profitable">
                                    <div className="advantages-list__item-box">
                                        <div className="advantages-list__item-icon" />
                                        <div className="advantages-list__item-container advantages-list__item-container--profitable">
                                            <h3 className="advantages-list__item-title advantages-list__item-title">Winstgevend</h3>
                                            <p className="advantages-list__item-description">De Groene Aggregaat is binnen 1 jaar terugverdiend.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="advantages-list__item advantages-list__item--reduction">
                                    <div className="advantages-list__item-box">
                                        <div className="advantages-list__item-icon" />
                                        <div className="advantages-list__item-container advantages-list__item-container--reduction">
                                            <h3 className="advantages-list__item-title">Meer dan 90% CO2 reductie</h3>
                                            <p className="advantages-list__item-description">Verbruik minder brandstof én bespaar het milieu.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="advantages-list__item  advantages-list__item--easy">
                                    <div className="advantages-list__item-box">
                                        <div className="advantages-list__item-icon" />
                                        <div className="advantages-list__item-container advantages-list__item-container--easy">
                                            <h3 className="advantages-list__item-title">Gemakkelijk in gebruik</h3>
                                            <p className="advantages-list__item-description">Één druk op de knop en de aggregaat is geactiveerd!</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="advantages-list__item advantages-list__item--silent">
                                    <div className="advantages-list__item-box">
                                        <div className="advantages-list__item-icon" />
                                        <div className="advantages-list__item-container advantages-list__item-container--silent">
                                            <h3 className="advantages-list__item-title">Geluidsarm</h3>
                                            <p className="advantages-list__item-description">Voorkom geluidsoverlast! Onze aggregaten zijn geluidsarm.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="exchange" onClick={this.onBannerClick}>
                            <div className="exchange__wrapper">
                                <img className="exchange__logo exchange-desktop-only" src={logoInruilSVG} width="356" height="113" alt="De Groene Inruildeals logo" />
                                <img className="exchange__logo exchange-mobile-only" src={logoInruilSVGMobile} width="356" height="113" alt="De Groene Inruildeals logo" />
                                <div className="exchange__description-wrapper">
                                    <p className="exchange__description exchange__description--first">Ruil uw vervuilende aggregaat bij ons in...</p>
                                    <p className="exchange__description exchange__description--second">
                                        En ontvang inruilkorting op een
                                        <br />
                                        groene aggregaat
                                    </p>
                                    <p className="exchange__description exchange__description--mobile">
                                        En ontvang inruilkorting op een <br />
                                        <span>hybride aggregaat</span>
                                    </p>
                                </div>
                                <NavLink className="exchange__link" to="/green-deals" aria-label="Naar de groene inruildeals" />
                                <div onClick={this.setModal} className="exchange__link-mobile">
                                    Offerte opvragen
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
                <MadeBy />
            </>
        );
    }
}

function mapStateToProps(state, props) {
    return { routeProps: props };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    navigateToGreenDealsAction: index => {
        console.log('probeer te navigeren --> ', index, ownProps);
        ownProps.history.push('/green-deals');
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
