// Modules
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

// Styling
import './Header.scss';
import './HeaderPage.scss';
import './HeaderMobile.scss';

// Images
import logoHeaderSVG from '../img/logo-dg-header.svg';
import WASVG from '../img/whatsapp.svg';
import hamburger from '../img/hamburgermenu.svg';

import Paul_Robbers from '../img/contact/Paul_Robbers.jpg';
// import Gabriel_Duursma from '../img/contact/Gabriel_Duursma.jpg';
// import Teamfoto from '../img/contact/teamfoto-stel-de-groene-aggregaat-een-vraag-over-hybride-oplossingen.jpg';

// Constants
import { modelsRoutes, navigationRoutes } from './constants';

const StelEenVraag = props => (
    <button className="page-header__button-question page-header__button-question--invisible site-button" type="button" onClick={props.onClick}>
        Stel een vraag
    </button>
);

const LogoHomePage = () => (
    <NavLink className="page-header__logo" to="/">
        <img className="page-header__logo-image" src={logoHeaderSVG} width="230" height="80" alt="De groene aggregaat logo" />
    </NavLink>
);
const LogoMarketingPage = () => (
    <a
        className="page-header__logo"
        href={'#'}
        onClick={e => {
            e.preventDefault();
        }}
    >
        <img className="page-header__logo-image" src={logoHeaderSVG} width="230" height="80" alt="De groene aggregaat logo" />
    </a>
);

const NavItem = ({ route }) => (
    <li className={`main-nav__list-item ${route.classBem}`}>
        <NavLink className="site-link" to={route.uri}>
            {route.label}
        </NavLink>
    </li>
);

const ModelsDropDownMenu = props => (
    <div className="dropdown">
        <button
            className={cx('dropbtn', {
                'dropbtn--index': props.isIndexRoute,
            })}
        >
            modellen
        </button>
        <div className="dropdown-content">
            {modelsRoutes.map((route, key) => (
                <NavLink key={key} className="site-link" to={route.uri}>
                    {route.label}
                </NavLink>
            ))}
        </div>
    </div>
);

export class MyModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            spinner: false,
            formComplete: false,
        };
    }

    requestComplete = response => {
        this.setState({ spinner: false, formComplete: true });
        console.log(JSON.parse(response));
    };

    handleSubmit = e => {
        const { requestComplete } = this;
        e.preventDefault();
        this.setState({ spinner: true });

        axios({
            method: 'post',
            url: '/api/contact',
            data: {
                name:  e.target.Naam.value,
                email: e.target['E-mailadres'].value,
                phone:  e.target.Telefoonnummer.value,
                company: e.target.Bedrijf.value,
                message: e.target.Bericht.value,
            },
            config: { headers: { 'Content-Type': 'multipart/form-data' } },
        })
            .then(function(response) {
                //handle success
                console.log('SUCCESS!!!!!');
                return JSON.stringify(response);
            })
            .catch(function(response) {
                //handle error
                return JSON.stringify(response);
            })
            .then(requestComplete);
    };
    render() {
        return (
            <Modal backdrop={this.props.visibility} show={this.props.visibility} onHide={this.props.onClick}>
                <Modal.Body>
                    <div
                        className={cx('modal-question', {
                            'modal-question-open': this.props.visibility,
                            'modal-question-close': !this.props.visibility,
                        })}
                    >
                        <div className="bug-free-scroll">
                            <button
                                className="modal-question__button-close modal-question__button-close--invisible site-button button-reset"
                                type="button"
                                onClick={() => {
                                    this.setState({ spinner: false, formComplete: false });
                                    this.props.onClick();
                                }}
                            >
                                <i className={'material-icons'}>close</i>
                                <span>Sluit</span>
                            </button>
                            <h2 className="modal-question__heading">{this.props.title || 'Wij zijn bereikbaar voor al uw vragen'}</h2>
                            <div className="modal-question__wrapper">
                                <div className="contact-block">
                                    {this.props.showGreenTile && (
                                        <p className="modal-question__contact" onClick={() => window.open('tel:+31299416641')}>
                                            Bel ons op werkdagen van 9:00 tot 17:00 uur op +31 299 – 41 66 41
                                        </p>
                                    )}{' '}
                                    <a style={{ float: 'left' }} target={'_blank'} href={'https://wa.me/31299416641'}>
                                        <img className={'wasvg'} src={WASVG} />
                                    </a>
                                </div>
                                <div className="modal-question__photo">
                                    <img src={this.props.salesImage ? Paul_Robbers : Paul_Robbers} width="200" height="200" alt="Foto van een medewerker" />
                                </div>
                            </div>
                            {this.state.formComplete ? (
                                <p style={{ marginTop: '35px', fontWeight: 'bold', backgroundColor: '#B8E4C9', padding: '15px 5px ' }}>
                                    Hartelijk dank voor uw bericht! We proberen zo spoedig mogelijk te reageren op uw vraag.
                                </p>
                            ) : this.state.spinner ? (
                                <p>Gegevens verzenden...</p>
                            ) : (
                                <form
                                    className="question-form"
                                    // action="https://www.degroeneaggregaat.nl/phpmailer/contact-form.php"
                                    method="post"
                                    onSubmit={this.handleSubmit}
                                >
                                    <p className="question-form__intro">Of vul onderstaand formulier in:</p>

                                    <div className="form-container">
                                        <input type="text" required name="Naam" placeholder="Naam" />
                                        <input type="text" required name="Bedrijf" placeholder="Bedrijf" />
                                        <input type="tel" required name="Telefoonnummer" placeholder="Telefoonnummer" />
                                        <input type="email" required name="E-mailadres" placeholder="E-mailadres" />
                                        <textarea name="Bericht" placeholder="Bericht" />
                                        <button type="submit">Verzend</button>
                                    </div>
                                </form>
                            )}
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

// Hook
function useWindowSize() {
    const isClient = typeof window === 'object';

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

const Header = ({ isMarketing }) => {
    const isIndexRoute = window.location.pathname === '/';

    const [visibility, setVisibility] = useState(false);
    const [menuMobile, setMenuMobile] = useState(false);

    // Make sure the mobile menu closes when the location is different
    if (window.location.href !== window.cachedHref) {
        setMenuMobile(false);
        window.cachedHref = window.location.href;
    }

    // Use the nice useWindowSize hook
    const size = useWindowSize();

    // Add an effect that updates the state when moving the menu mobile open to desktop
    // so that it closes the menu
    useEffect(
        () => {
            if (size.width > 1050 && menuMobile) {
                setMenuMobile(false);
            }
        },
        [size.width]
    );

    return (
        <header
            className={cx('page-header', {
                'page-header--index': isIndexRoute,
                'page-header--marketing': isMarketing,
            })}
        >
            {!isMarketing && <MyModal showGreenTile={true} visibility={visibility} onClick={() => setVisibility(!visibility)} />}
            {isMarketing ? <LogoMarketingPage /> : <LogoHomePage />}
            {!isMarketing && (
                <nav className="main-nav">
                    <button className="main-nav__toggle button-reset" type="button" aria-label="Menu openen">
                        <div className="main-nav__toggle-icon" onClick={() => setMenuMobile(!menuMobile)}>
                            <img className={'hamburger'} src={hamburger} alt={'Menu openen'} />
                        </div>
                    </button>

                    <div
                        className={cx('main-nav__wrapper', {
                            'main-nav__wrapper--mobile': menuMobile,
                        })}
                    >
                        <ul className="main-nav__list site-list">
                            {menuMobile ? (
                                modelsRoutes.map((route, key) => <NavItem key={key} route={route} />)
                            ) : (
                                <li className="main-nav__list-item main-nav__list-item--models">
                                    <ModelsDropDownMenu isIndexRoute={isIndexRoute} />
                                </li>
                            )}

                            {navigationRoutes.map((route, key) => (
                                <NavItem key={key} route={route} onClick={() => setMenuMobile(!menuMobile)} />
                            ))}
                        </ul>
                    </div>
                    <StelEenVraag onClick={() => setVisibility(!visibility)} />
                </nav>
            )}
        </header>
    );
};

export default Header;
