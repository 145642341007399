// Modules
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// Components
import Gallery from '../../components/Gallery';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

// Styling
import './InActie.scss';

import image1 from '../../img/inactie/K-Dekker-huurt-de-groene-hybride-aggregaat-voor-de-noord-zuidlijn-amsterdam.jpg';
import image2 from '../../img/inactie/van-hattum-en-blankevoort-huurt-onze-groene-hybride-aggregaat-amsterdam-bouwproject.jpg';
import image3 from '../../img/inactie/boels-verhuurt-de-groene-hybride-aggregaat-online.jpg';
import image4 from '../../img/inactie/defensie-en-de-groene-aggregaat-voor-hybride-schone-energie.jpg';
import image5 from '../../img/inactie/schiphol-huurt-groene-aggregaat-klm.jpg';
import image6 from '../../img/inactie/lokaal-evenement-kleinschalig-huurt-groene-aggregaat-festival.jpg';
import MadeBy from '../../components/MadeBy';

const getStartIndexByProject = routeProps => {
    const path = routeProps.match.path;
    let startIndex = 0;

    switch (path) {
        case '/in-actie/van-hattum-huurt-de-groene-aggregaat':
            startIndex = 1;
            break;

        case '/in-actie/boels-verhuurt-de-hybride-groene-aggregaat':
            startIndex = 2;
            break;

        case '/in-actie/de-groene-aggregaat-bij-defensie':
            startIndex = 3;
            break;

        case '/in-actie/de-groene-aggregaat-op-schiphol':
            startIndex = 4;
            break;

        case '/in-actie/huur-de-hybride-groene-aggregaat-voor-uw-evenement':
            startIndex = 5;
            break;

        default:
            startIndex = 0;
            break;
    }

    return startIndex;
};

const getProjectText = (routeProps, textType) => {
    const path = routeProps.match.path;
    const projectTexts = {
        '/in-actie/hybride-groene-aggregaat-bij-noord-zuid-lijn': {
            title: 'N-Z lijn',
            short: 'Geen decibel geluid in de late uurtjes',
            meta: (
                <Helmet>
                    <title>Noord-Zuid lijn | De Groene Aggregaat Verhuur</title>
                    <meta name="description" content="Geen decibel geluid in de late uurtjes" />
                </Helmet>
            ),
            info:
                'K. Dekker kiest bewust voor De Groene Aggregaat op projecten in stedelijk gebied. Dit vanwege de Co2 reductie en de mogelijkheid om binnen de geluidsnormen te blijven tijdens de avond- en nachturen. De hybride functionaliteit van De Groene Aggregaat zorgt namelijk desgewenst voor stroom zonder een decibel geluid.',
        },
        '/in-actie/van-hattum-huurt-de-groene-aggregaat': {
            title: 'Van Hattum',
            short: 'Duurzame bouwplaats',
            meta: (
                <Helmet>
                    <title>Van Hattum | De Groene Aggregaat Verhuur</title>
                    <meta name="description" content="Geen decibel geluid in de late uurtjes" />
                </Helmet>
            ),
            info:
                'De Groene Aggregaat is meegenomen in de bouwplaatsinrichting voor project Randstad 380kV-Noordring door Van Hattum en Blankevoort. Dit vanwege de Co2 reductie en brandstofbesparing. De Groene Aggregaat heeft een trotste bijdrage geleverd aan een duurzame bouwplaats.',
        },
        '/in-actie/boels-verhuurt-de-hybride-groene-aggregaat': {
            title: 'Boels',
            short: 'Huur ook bij Boels',
            meta: (
                <Helmet>
                    <title>Boels | De Groene Aggregaat Verhuur</title>
                    <meta name="description" content="Huur ook bij Boels" />
                </Helmet>
            ),
            info:
                'Boels heeft De Groene Aggregaat opgenomen in hun verhuurprogramma. Door deze samenwerking kunt u vanaf nu ook bij uw favoriete Boels vestiging terecht voor onze hybride aggregaat. Informeer bij Boels voor beschikbaarheid en verhuurprijzen.\n',
        },
        '/in-actie/de-groene-aggregaat-bij-defensie': {
            title: 'Defensie',
            short: 'De groene kracht van defensie',
            meta: (
                <Helmet>
                    <title>Defensie | De Groene Aggregaat Verhuur</title>
                    <meta name="description" content="De groene kracht van defensie" />
                </Helmet>
            ),
            info:
                'De Nederlandse Defensie heeft De Groene Aggregaat ingezet als stroomvoorziening van een tenten kamp. Vanuit deze samenwerking wordt er binnen Maru Systems gekeken naar verder ontwikkeling van het product op deze afzetmarkt.',
        },
        '/in-actie/de-groene-aggregaat-op-schiphol': {
            title: 'Schiphol',
            short: 'Verduurzaming van de luchtvaart',
            meta: (
                <Helmet>
                    <title>Schiphol | De Groene Aggregaat Verhuur</title>
                    <meta name="description" content="Verduurzaming van de luchtvaart" />
                </Helmet>
            ),
            info:
                'Voor opdrachtgever Volker Wessels maakt men al geruime tijd gebruik van hybride aggregaten op Schiphol. De Groene Aggregaat help hiermee het behalen van duurzaamheids doelstellingen op dit project voor deze opdrachtgever.',
        },
        '/in-actie/huur-de-hybride-groene-aggregaat-voor-uw-evenement': {
            title: 'Festival',
            short: 'Ook voor uw evenement',
            meta: (
                <Helmet>
                    <title>Festival | De Groene Aggregaat Verhuur</title>
                    <meta name="description" content="Ook voor uw evenement" />
                </Helmet>
            ),
            info:
                'De Groene Aggregaat draait al jaren mee op de meest uiteenlopende festivals. Vaak op de achtergrond maar altijd vooraan op gebied van Co2 reductie. Wij helpen event organisaties met het verduurzamen van hun festivals.',
        },
    };

    return projectTexts[path][textType];
};

const InActie = props => {
    const images = [
        {
            original: image1,
            thumbnail: image1,
        },
        {
            original: image2,
            thumbnail: image2,
        },
        {
            original: image3,
            thumbnail: image3,
        },
        {
            original: image4,
            thumbnail: image4,
        },
        {
            original: image5,
            thumbnail: image5,
        },
        {
            original: image6,
            thumbnail: image6,
        },
    ];

    return (
        <>
            {getProjectText(props.routeProps, 'meta')}
            <Header />
            <main className="page-in-action">
                <h1 className="visually-hidden">De Groene Aggregaat in actie</h1>
                <section className="in-action">
                    <h2 className="in-action__heading">De groene aggregaat in actie</h2>
                    <p className="in-action__subheading">{getProjectText(props.routeProps, 'short')}</p>
                    <div className="in-action__gallery">
                        <div className="in-action__gallery--left">
                            <Gallery startIndex={getStartIndexByProject(props.routeProps)} navigateToAction={props.navigateToAction} items={images} />
                        </div>
                        <div className="in-action__gallery--right">
                            <h1 className="project-title">{getProjectText(props.routeProps, 'title')}</h1>
                            <div className="desktop-only">
                                <p>{getProjectText(props.routeProps, 'info')}</p>

                                <h2>Deze aggregaat huren?</h2>
                                <p>Of interesse in koop?</p>
                                <div className="comparison__contact-container--desktop">
                                    <div className="comparison__button contact-button contact-button--phone site-button" onClick={() => window.open('tel:+31299416641')}>
                                        <a className="site-link" href="tel:+31299416641" aria-label="Bel met de groene aggregaat">
                                            +31 299 - 416641
                                        </a>
                                    </div>
                                    <div
                                        className="comparison__button contact-button contact-button--mail site-button"
                                        onClick={() => window.open('mailto:verhuur@degroeneaggregaat.nl')}
                                    >
                                        <a className="site-link" href="mailto:verhuur@degroeneaggregaat.nl" aria-label="Mail de groene aggregaat">
                                            Stuur een bericht
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-only">
                                <br />
                                <p>{getProjectText(props.routeProps, 'info')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-contact mobile-only">
                        <p>Deze aggregaat huren?</p>

                        <div className="contact-button-container">
                            <div className="contact-button-mobile contact-button-mobile--phone site-button" onClick={() => window.open('tel:+31299416641')}>
                                <a className="site-link" href="tel:+31299416641" aria-label="Bel met de groene aggregaat" rel={'noopener'}>
                                    Bel ons
                                </a>
                            </div>
                            <div className="contact-button-mobile contact-button-mobile--mail site-button" onClick={() => window.open('mailto:verhuur@degroeneaggregaat.nl')}>
                                <a className="site-link" href="mailto:verhuur@degroeneaggregaat.nl" aria-label="Mail de groene aggregaat" rel={'noopener'}>
                                    Bericht
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <MadeBy />
        </>
    );
};

function mapStateToProps(state, props) {
    return { routeProps: props };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    navigateToAction: index => {
        console.log('probeer te navigeren --> ', index, ownProps);

        let project = 'hybride-groene-aggregaat-bij-noord-zuid-lijn';

        if (index === 1) {
            project = 'van-hattum-huurt-de-groene-aggregaat';
        }

        if (index === 2) {
            project = 'boels-verhuurt-de-hybride-groene-aggregaat';
        }

        if (index === 3) {
            project = 'de-groene-aggregaat-bij-defensie';
        }

        if (index === 4) {
            project = 'de-groene-aggregaat-op-schiphol';
        }

        if (index === 5) {
            project = 'huur-de-hybride-groene-aggregaat-voor-uw-evenement';
        }

        ownProps.history.push('/in-actie/' + project);
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InActie);
