// Modules
import React from 'react';
import cx from 'classnames';

// Assets
import model5 from '../../img/home-models/model-5/huur-onze-groene-aggregaat-model-5-een-hybride-off-grid-stroom-oplossing-klein.png';
import model30 from '../../img/home-models/model-30/huur-onze-groene-aggregaat-model-30-de-hybride-besparende-aggregaat-met-zonnepanelen-klein.png';
import model45 from '../../img/home-models/model-45/huur-onze-groene-aggregaat-model-45-bespaar-op-milileu-en-brandstof-met-de-hybride-stroom-oplossing-klein.png';
import model100 from '../../img/home-models/model-100/huur-onze-groene-aggregaat-model-100-hybride-aggregaat-met-zonnepanelen-veel-vermogen-en-laag-co2-uitstoot-klein.png';

// Styling
import './ModelSliderMarketing.scss';
import { MarketingModal } from './MarketingModal';
import { connect } from 'react-redux';

class ModelSliderMarketing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentModelIndex: 2,
            modalOfferte: false,
        };
    }
    getModelInfoByIndex = (index, property) => {
        return {
            '0': {
                name: 'Model 5',
                route: '/model-5',
                fuel: { min: '€5226', max: '€34.840' },
                co2: { min: '14.472kg', max: '96.480kg' },
                shortInfo:
                    'Diverse kleinere modellen zoals model 5 en 15 bewijzen al 7 jaar dat je zonder zonnepanelen milieubesparend bent. Sinds 2014 realiseren deze units een brandstofreducties en zijn ze ideaal voor iedere kleinere gelegenheid.',
            },
            '1': {
                name: 'Model 30',
                route: '/model-30',
                fuel: { min: '€8713', max: '€58.084' },
                co2: { min: '24.128kg', max: '160.848kg' },
                shortInfo:
                    'Model-30 in zijn 10ft container is het instapmodel met een geluidsarme generator en geïntegreerd opslag- en zonnepanelensysteem. Binnen 90 seconde geïnstalleerd en semiautomatisch,  eenvoudig te verplaatsen en ideaal voor geringe locaties.',
            },
            '2': {
                name: 'Model 45',
                route: '/model-45',
                fuel: { min: '€13.937', max: '€92.976' },
                co2: { min: '38.594kg', max: '257.472kg' },
                shortInfo:
                    'Model-45 in de standaard en bewijst zich al 5 jaar op locaties waarbij energiezekerheid maximaal moet zijn. Ons nieuw model-45 1.2 is een upgrade van het bestaand systeem. Nog zuiniger, efficiënter met de mogelijkheid tot brandstofkeuze. Kiest u voor HVO-100 of toch diesel? ',
            },
            '3': {
                name: 'Model 100',
                route: '/model-100',
                fuel: { min: '€37.760', max: '€255.632' },
                co2: { min: '104.566kg', max: '707.904kg' },
                shortInfo:
                    'Goliath online! Model-100 is het beest van De Groene Aggregaat met een 100kVa (HVO-100) en een volledig geïntegreerd systeem. Geen losse batterijen gekoppeld aan dieselaggregaten en zonnepanelen. Binnen 6 minuten operationeel, meer opslag en zonnepanelen en een hoger rendement.',
            },
        }[index][property];
    };
    setActiveModel = currentModelIndex => {
        console.log('currentModelIndex --> ', currentModelIndex);
        this.setState({ currentModelIndex });

        const outerFuel = document.getElementsByClassName('comparison__bar--outer-fuel')[0];
        const innerFuel = document.getElementsByClassName('comparison__bar-inner--fuel')[0];
        const outerCO2 = document.getElementsByClassName('comparison__bar--outer-co2')[0];
        const innerCO2 = document.getElementsByClassName('comparison__bar-inner--co2')[0];

        if (outerFuel && innerFuel && outerCO2 && innerCO2) {
            if (outerFuel.classList.contains('animate')) {
                outerFuel.classList.remove('animate');
                innerFuel.classList.remove('animate');
                outerCO2.classList.remove('animate');
                innerCO2.classList.remove('animate');
            }

            setTimeout(() => {
                outerFuel.classList.add('animate');
                innerFuel.classList.add('animate');
                outerCO2.classList.add('animate');
                innerCO2.classList.add('animate');
            }, 1);
        }
    };
    onScroll = () => {
        const element = document.getElementsByClassName('intro__next')[0];
        if (element) {
            element.style.opacity = 1 - window.scrollY / 250;
        }
        const outerFuel = document.getElementsByClassName('comparison__bar--outer-fuel')[0];
        const innerFuel = document.getElementsByClassName('comparison__bar-inner--fuel')[0];
        const outerCO2 = document.getElementsByClassName('comparison__bar--outer-co2')[0];
        const innerCO2 = document.getElementsByClassName('comparison__bar-inner--co2')[0];

        // Animate the progress bars
        if (window.scrollY > 500) {
            if (outerFuel && innerFuel && outerCO2 && innerCO2) {
                if (!outerFuel.classList.contains('animate')) {
                    outerFuel.classList.add('animate');
                    innerFuel.classList.add('animate');
                    outerCO2.classList.add('animate');
                    innerCO2.classList.add('animate');
                }
            }
        } else {
            if (outerFuel && innerFuel && outerCO2 && innerCO2) {
                if (outerFuel.classList.contains('animate')) {
                    outerFuel.classList.remove('animate');
                    innerFuel.classList.remove('animate');
                    outerCO2.classList.remove('animate');
                    innerCO2.classList.remove('animate');
                }
            }
        }
    };

    updateDimensions = () => {
        if (window.innerWidth >= 750) {
            this.setState({ width: window.innerWidth, height: window.innerHeight });
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.onScroll);
    }
    render() {
        return (
            <>
                <MarketingModal
                    onThanks={this.props.navigateToModelBedankt}
                    visibility={this.state.modalOfferte}
                    onClick={() => this.setState({ modalOfferte: !this.state.modalOfferte })}

                    doelgroep={this.props.doelgroep}
                    offerteModal={true}
                    model={this.getModelInfoByIndex(this.state.currentModelIndex, 'name')}
                />

                <div>
                    <ul className="model-list site-list">
                        <li
                            className={cx('model-list__item', {
                                'model-list__item--active': this.state.currentModelIndex === 0,
                            })}
                            onClick={() => this.setActiveModel(0)}
                        >
                            <img src={model5} className={'marketing-model5'} alt="Model 5" />
                            <div className="model-list__wrapper">
                                <a className="model-list__link site-link">Model 5</a>
                            </div>
                        </li>
                        <li
                            className={cx('model-list__item', {
                                'model-list__item--active': this.state.currentModelIndex === 1,
                            })}
                            onClick={() => this.setActiveModel(1)}
                        >
                            <img className="model-list__image marketing-model30" src={model30} alt="Model 30" />
                            <div className="model-list__wrapper">
                                <a className="model-list__link site-link">Model 30</a>
                            </div>
                        </li>
                        <li
                            className={cx('model-list__item', {
                                'model-list__item--active': this.state.currentModelIndex === 2,
                            })}
                            onClick={() => this.setActiveModel(2)}
                        >
                            <img src={model45} className={'marketing-model45'} alt="Model 45" />
                            <div className="model-list__wrapper">
                                <a className="model-list__link site-link">Model 45</a>
                            </div>
                        </li>
                        <li
                            className={cx('model-list__item', {
                                'model-list__item--active': this.state.currentModelIndex === 3,
                            })}
                            onClick={() => this.setActiveModel(3)}
                        >
                            <img src={model100} className={'marketing-model100'} alt="Model 100" />
                            <div className="model-list__wrapper">
                                <a className="model-list__link site-link">Model 100</a>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="comparison--marketing">
                    <div className="comparison__model">
                        <h3 className="comparison__heading comparison__heading--hidden">{this.getModelInfoByIndex(this.state.currentModelIndex, 'name')}</h3>
                        <p className="comparison__description">{this.getModelInfoByIndex(this.state.currentModelIndex, 'shortInfo')}</p>
                    </div>
                    <div className="comparison__graph">
                        <div className="comparison__wrapper">
                            <h3 className="comparison__heading comparison__heading--inline-block">Uw besparing</h3>
                        </div>
                        <div className={'colorful-text'}>
                            <span className="comparison__versus comparison__versus--green">De Groene Aggregaat</span>
                            <span className="comparison__versus comparison__versus--regular">versus</span>
                            <span className="comparison__versus comparison__versus--red">Conventioneel</span>
                        </div>

                        <div className="animation-box animation-box--top">
                            <p className="comparison__title">Brandstofkosten per jaar</p>
                            <div className="comparison__bar comparison__bar--outer-fuel">
                                <div className="comparison__bar-inner comparison__bar-inner--fuel" />

                                <div className="comparison__number-container comparison__number-container--fuel">
                                    <div className="comparison__number comparison__number--small">{this.getModelInfoByIndex(this.state.currentModelIndex, 'fuel').min}</div>
                                    <div className="comparison__number comparison__number--big">{this.getModelInfoByIndex(this.state.currentModelIndex, 'fuel').max}</div>
                                </div>
                            </div>
                        </div>

                        <div className="animation-box">
                            <p className="comparison__title">CO2- uitstoot in kg per jaar</p>
                            <div className="comparison__bar comparison__bar--outer-co2">
                                <div className="comparison__bar-inner comparison__bar-inner--co2" />

                                <div className="comparison__number-container comparison__number-container--co2">
                                    <div className="comparison__number comparison__number--small">{this.getModelInfoByIndex(this.state.currentModelIndex, 'co2').min}</div>
                                    <div className="comparison__number comparison__number--big">{this.getModelInfoByIndex(this.state.currentModelIndex, 'co2').max}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="rent">
                        <h3 className="comparison__heading comparison__heading--rent">Deze aggregaat huren?</h3>
                        <p className="comparison__subheading comparison__subheading--desktop">Of interesse in koop?</p>
                        <div className="comparison__contact-container--desktop">
                            <div
                                className="comparison__button contact-button contact-button--mail site-button"
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.setState({ modalOfferte: true });
                                }}
                            >
                                Vraag offerte aan
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ModelSliderMarketing;
