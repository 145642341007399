// Modules
import React, { useState, useEffect, useRef, useCallback } from 'react';

// Styling
import './exchange.scss';
import './exchange-mobile.scss';

import dgaLogoSVG from '../../img/logo-groene-inruildeals.svg';
import Modal from 'react-bootstrap/Modal';
import cx from 'classnames';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

// Hook
function useEventListener(eventName, handler, element = window) {
    // Create a ref that stores handler
    const savedHandler = useRef();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            // Create event listener that calls handler function stored in ref
            const eventListener = (event) => savedHandler.current(event);

            // Add event listener
            element.addEventListener(eventName, eventListener);

            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element] // Re-run if eventName or element changes
    );
}

export class OfferteModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            spinner: false,
            formComplete: false,
            photoInsideBase64: null,
            photoOutsideBase64: null,
        };
    }

    requestComplete = (response) => {
        this.setState({ spinner: false, formComplete: true });
        console.log(JSON.parse(response));
    };

    handleSubmit = (e) => {
        const { requestComplete } = this;
        e.preventDefault();

        this.setState({ spinner: true });

        axios({
            method: 'post',
            url: '/api/green-deals',
            data: {
                name: e.target['Naam'].value,
                company: e.target['Bedrijf'].value,
                phone: e.target['Telefoonnummer'].value,
                email: e.target['E-mailadres'].value,
                brand: e.target['Merk-inruilaggregaat'].value,
                year: e.target['Jaar-inruilaggregaat'].value,
                hours: e.target['Aantal-draaiuren'].value,
                power: e.target['Vermogen'].value,
                photo_inside: this.state.photoInsideBase64,
                photo_outside: this.state.photoOutsideBase64,
            },
            config: { headers: { 'Content-Type': 'multipart/form-data' } },
        })
            .then(function (response) {
                //handle success
                console.log('SUCCESS!!!!!');
                return JSON.stringify(response);
            })
            .catch(function (response) {
                //handle error
                return JSON.stringify(response);
            })
            .then(requestComplete);
    };
    render() {

        const self = this;

        return (
            <Modal
                backdrop={this.props.visibility}
                show={this.props.visibility}
                onHide={() => {
                    console.log('hide?');
                    this.props.onClick();
                }}
            >
                <Modal.Body>
                    <div
                        className={cx('modal-question', {
                            'modal-question-open': this.props.visibility,
                            'modal-question-close': !this.props.visibility,
                        })}
                    >
                        <div className={'bug-free-scroll'}>
                            <button
                                className="modal-question__button-close modal-question__button-close--invisible site-button button-reset"
                                type="button"
                                onClick={() => {
                                    this.setState({ spinner: false, formComplete: false });
                                    this.props.onClick();
                                }}
                            >
                                <i className={'material-icons'}>close</i>
                                <span>Sluit</span>
                            </button>

                            <h2 className="modal-question__heading">{this.props.title || 'Green deals'}</h2>
                            <p className="modal-small-intro">
                                Wij uw vervuilende aggregaat, u een duurzaam hybride aggregaat én een aantrekkelijke inruilkorting. Dat is de kern van onze unieke inruildeal. Als u
                                het formulier hieronder invult en naar ons verzendt, ontvangt u van ons z.s.m. een aantrekkelijk inruilvoorstel.
                            </p>
                            <div>
                                {this.state.formComplete ? (
                                    <p style={{ marginTop: '35px', fontWeight: 'bold', backgroundColor: '#B8E4C9', padding: '15px 5px ' }}>
                                        Hartelijk dank voor uw bericht! We nemen zo spoedig mogelijk contact met u op.
                                    </p>
                                ) : this.state.spinner ? (
                                    <p>Offerte verzenden...</p>
                                ) : (
                                    <form className="question-form" onSubmit={this.handleSubmit} method="post" encType={'multipart/form-data'}>
                                        <p className="question-form__intro">Vraag offerte aan:</p>
                                        <div className="form-container">
                                            <input type="text" required name="Naam" placeholder="Naam" autoFocus={true} />
                                            <input type="text" required name="Bedrijf" placeholder="Bedrijf" />
                                            <input type="tel" required name="Telefoonnummer" placeholder="Telefoonnummer" />
                                            <input type="email" required name="E-mailadres" placeholder="E-mailadres" />
                                            <input type="text" required name="Merk-inruilaggregaat" placeholder="Merk inruilaggregaat" />
                                            <input type="text" required name="Jaar-inruilaggregaat" placeholder="Jaar inruilaggregaat" />
                                            <input type="text" required name="Aantal-draaiuren" placeholder="Aantal draaiuren" />
                                            <label htmlFor={'upload-inside'}>Upload foto binnenzijde</label>
                                            <input
                                                id={'upload-inside'}
                                                required
                                                type="file"
                                                accept=".png, .jpg"
                                                name="Foto-binnenzijde"
                                                onChange={(e) => {
                                                    if (e.target.files) {
                                                        let imageFile = e.target.files[0]; //here we get the image file
                                                        if (imageFile.size >= 500000) {
                                                            alert('Bestand mag niet groter zijn dan 500kb');
                                                            return;
                                                        }
                                                        var reader = new FileReader();
                                                        reader.readAsDataURL(imageFile);
                                                        reader.onloadend = function (e) {
                                                            var myImage = new Image(); // Creates image object
                                                            myImage.src = e.target.result; // Assigns converted image to image object
                                                            myImage.onload = function (ev) {
                                                                var myCanvas = document.getElementById('canvasInsideMobile'); // Creates a canvas object
                                                                var myContext = myCanvas.getContext('2d'); // Creates a contect object
                                                                myCanvas.width = myImage.width; // Assigns image's width to canvas
                                                                myCanvas.height = myImage.height; // Assigns image's height to canvas
                                                                myContext.drawImage(myImage, 0, 0); // Draws the image on canvas
                                                                let imgData = myCanvas.toDataURL('image/jpeg', 1); // Assigns image base64 string in jpeg format to a variable
                                                                self.setState({ photoInsideBase64: imgData });
                                                            };
                                                        };
                                                    }
                                                }}
                                            />
                                            <canvas id="canvasInsideMobile" width="1000" height="700"></canvas>
                                            <label htmlFor={'upload-outside'}> Upload foto buitenzijde</label>
                                            <input
                                                id={'upload-outside'}
                                                required
                                                type="file"
                                                accept=".png, .jpg"
                                                name="Foto-buitenzijde"
                                                onChange={(e) => {
                                                    if (e.target.files) {
                                                        let imageFile = e.target.files[0]; //here we get the image file
                                                        if (imageFile.size >= 2000000) {
                                                            alert('Bestand mag niet groter zijn dan 2 Mb');
                                                            return;
                                                        }
                                                        var reader = new FileReader();
                                                        reader.readAsDataURL(imageFile);
                                                        reader.onloadend = function (e) {
                                                            var myImage = new Image(); // Creates image object
                                                            myImage.src = e.target.result; // Assigns converted image to image object
                                                            myImage.onload = function (ev) {
                                                                var myCanvas = document.getElementById('canvasOutsideMobile'); // Creates a canvas object
                                                                var myContext = myCanvas.getContext('2d'); // Creates a contect object
                                                                myCanvas.width = myImage.width; // Assigns image's width to canvas
                                                                myCanvas.height = myImage.height; // Assigns image's height to canvas
                                                                myContext.drawImage(myImage, 0, 0); // Draws the image on canvas
                                                                let imgData = myCanvas.toDataURL('image/jpeg', 1); // Assigns image base64 string in jpeg format to a variable
                                                                self.setState({ photoOutsideBase64: imgData });
                                                            };
                                                        };
                                                    }
                                                }}
                                            />
                                            <canvas id="canvasOutsideMobile" width="1000" height="700"></canvas>
                                            <textarea
                                                className="request-form__input request-form__input--textarea"
                                                name="Vermogen"
                                                required
                                                placeholder="Welk vermogen zoekt u voor de nieuwe hybride aggregaat in kVA?"
                                            />
                                            <button type="submit">Verzend</button>
                                        </div>
                                    </form>
                                )}
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const GreenDeals = () => {
    const [visibility, setVisibility] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [formComplete, setFormComplete] = useState(false);
    const [photoInsideBase64, setPhotoInsideBase64] = useState(null);
    const [photoOutsideBase64, setPhotoOutsideBase64] = useState(null);

    // State for storing mouse coordinates
    const [height, setHeight] = useState(window.innerHeight - 100);

    // Event handler utilizing useCallback ...
    // ... so that reference never changes.
    const handler = useCallback(() => {
        // Update coordinates
        setHeight(window.innerHeight - 100);
    }, [setHeight]);

    // Add event listener using our hook
    useEventListener('resize', handler);

    return (
        <main className="page-main page-green-deals" style={{ height }}>
            <Helmet>
                <title>Ontvang inruilkorting! | De Groene Aggregaat Verhuur</title>
                <meta name="description" content="Ruil uw vervuilende aggregaat bij ons in." />
            </Helmet>
            <OfferteModal visibility={visibility} onClick={() => setVisibility(!visibility)} />
            <section className="green-deals" style={{ height }}>
                <h1 className="visually-hidden">Inruilactie</h1>
                <div className="exchange__wrapper">
                    <div className="discount">
                        <img className="discount__logo" src={dgaLogoSVG} alt="Logo De Groene Aggregaat" width="500" height="220" />
                        <div className="discount__wrapper discount__wrapper--desktop">
                            <b className="discount__red">Ruil uw vervuilende aggregaat bij ons in...</b>
                            <b className="discount__green">en ontvang inruilkorting</b>
                            <br />
                            <b className="discount__blue">op een hybride aggregaat</b>
                        </div>
                        <div className="discount__wrapper discount__wrapper--mobile">
                            <b className="discount__red">
                                Ruil uw vervuilende
                                <br />
                                aggregaat bij ons in...
                            </b>
                            <b className="discount__green">
                                en ontvang
                                <br />
                                inruilkorting op een
                            </b>
                            <b className="discount__blue">hybride aggregaat</b>
                        </div>
                    </div>
                    <button className="exchange__button-mobile" onClick={() => setVisibility(!visibility)} type="button>">
                        Offerte opvragen
                    </button>
                    <div className="offer">
                        <h2 className="offer__heading">vraag een offerte aan</h2>
                        <p className="offer__description">
                            Wij uw vervuilende aggregaat, u een duurzaam hybride aggregaat én een aantrekkelijke inruilkorting. Dat is de kern van onze unieke inruildeal. Als u het
                            formulier hieronder invult en naar ons verzendt, ontvangt u van ons z.s.m. een aantrekkelijk inruilvoorstel.
                        </p>

                        {formComplete ? (
                            <p style={{ marginTop: '35px', fontWeight: 'bold', backgroundColor: '#B8E4C9', padding: '15px 5px ' }}>
                                Hartelijk dank voor uw bericht! We nemen zo spoedig mogelijk contact met u op.
                            </p>
                        ) : spinner ? (
                            <p>Offerte verzenden...</p>
                        ) : (
                            <form
                                className="request-form"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setSpinner(true);

                                    axios({
                                        method: 'post',
                                        url: '/api/green-deals',
                                        data: {
                                            name: e.target['Naam'].value,
                                            company: e.target['Bedrijf'].value,
                                            phone: e.target['Telefoonnummer'].value,
                                            email: e.target['E-mailadres'].value,
                                            brand: e.target['Merk-inruilaggregaat'].value,
                                            year: e.target['Jaar-inruilaggregaat'].value,
                                            hours: e.target['Aantal-draaiuren'].value,
                                            power: e.target['Vermogen'].value,
                                            photo_inside: photoInsideBase64,
                                            photo_outside: photoOutsideBase64,
                                        },
                                        config: { headers: { 'Content-Type': 'multipart/form-data' } },
                                    })
                                        .then(function (response) {
                                            //handle success
                                            console.log('SUCCESS!!!!!');
                                            return JSON.stringify(response);
                                        })
                                        .catch(function (response) {
                                            //handle error
                                            return JSON.stringify(response);
                                        })
                                        .then(() => {
                                            // set th state
                                            setFormComplete(true);
                                        });
                                }}
                                method="post"
                                encType={'multipart/form-data'}
                            >
                                <input
                                    className="request-form__input request-form__input--first-name"
                                    required
                                    type="text"
                                    name="Naam"
                                    aria-label="voornaam"
                                    placeholder="Naam"
                                    autoFocus={true}
                                />
                                <input className="request-form__input request-form__input--last-name" type="text" name="Achternaam" style={{ visibility: 'hidden' }} />
                                <input
                                    className="request-form__input request-form__input--company"
                                    required
                                    type="text"
                                    name="Bedrijf"
                                    aria-label="Bedrijf"
                                    placeholder="Bedrijf"
                                />
                                <input
                                    className="request-form__input request-form__input--phone"
                                    required
                                    type="tel"
                                    name="Telefoonnummer"
                                    aria-label="Telefoonnummer"
                                    placeholder="Telefoonnummer"
                                />
                                <input
                                    className="request-form__input request-form__input--mail"
                                    required
                                    type="email"
                                    name="E-mailadres"
                                    aria-label="e-mailadres"
                                    placeholder="E-mailadres"
                                />
                                <input
                                    className="request-form__input request-form__input--brand"
                                    required
                                    type="text"
                                    name="Merk-inruilaggregaat"
                                    aria-label="merk inruilaggregaat"
                                    placeholder="Merk inruilaggregaat"
                                />
                                <input
                                    className="request-form__input request-form__input--year"
                                    required
                                    type="text"
                                    name="Jaar-inruilaggregaat"
                                    aria-label="jaar inruilaggregaat"
                                    placeholder="Jaar inruilaggregaat"
                                />
                                <input
                                    className="request-form__input request-form__input--hours"
                                    required
                                    type="text"
                                    name="Aantal-draaiuren"
                                    aria-label="aantal draaiuren"
                                    placeholder="Aantal draaiuren"
                                />
                                <label className="request-form__label request-form__label--inner">
                                    Upload foto binnenzijde
                                    <input
                                        className="request-form__file request-form__input--photo-inner"
                                        accept=".png, .jpg"
                                        required
                                        type="file"
                                        onChange={(e) => {
                                            if (e.target.files) {
                                                let imageFile = e.target.files[0]; //here we get the image file
                                                if (imageFile.size >= 2000000) {
                                                    alert('Bestand mag niet groter zijn dan 2 Mb');
                                                    return;
                                                }
                                                var reader = new FileReader();
                                                reader.readAsDataURL(imageFile);
                                                reader.onloadend = function (e) {
                                                    var myImage = new Image(); // Creates image object
                                                    myImage.src = e.target.result; // Assigns converted image to image object
                                                    myImage.onload = function (ev) {
                                                        var myCanvas = document.getElementById('canvasInside'); // Creates a canvas object
                                                        var myContext = myCanvas.getContext('2d'); // Creates a contect object
                                                        myCanvas.width = myImage.width; // Assigns image's width to canvas
                                                        myCanvas.height = myImage.height; // Assigns image's height to canvas
                                                        myContext.drawImage(myImage, 0, 0); // Draws the image on canvas
                                                        let imgData = myCanvas.toDataURL('image/jpeg', 1.0); // Assigns image base64 string in jpeg format to a variable
                                                        setPhotoInsideBase64(imgData);
                                                    };
                                                };
                                            }
                                        }}
                                        name="Foto-binnenzijde"
                                        aria-label="foto binnenzijde"
                                    />
                                </label>
                                <canvas id="canvasInside" width="1000" height="700"></canvas>
                                <label className="request-form__label request-form__label--outer">
                                    Upload foto buitenzijde
                                    <input
                                        className="request-form__file request-form__input--photo-outer"
                                        accept=".png, .jpg"
                                        required
                                        type="file"
                                        name="Foto-buitenzijde"
                                        aria-label="Foto buitenzijde"
                                        onChange={(e) => {
                                            if (e.target.files) {
                                                let imageFile = e.target.files[0]; //here we get the image file
                                                if (imageFile.size >= 500000) {
                                                    alert('Bestand mag niet groter zijn dan 500kb');
                                                    return;
                                                }
                                                var reader = new FileReader();
                                                reader.readAsDataURL(imageFile);
                                                reader.onloadend = function (e) {
                                                    var myImage = new Image(); // Creates image object
                                                    myImage.src = e.target.result; // Assigns converted image to image object
                                                    myImage.onload = function (ev) {
                                                        var myCanvas = document.getElementById('canvasOutside'); // Creates a canvas object
                                                        var myContext = myCanvas.getContext('2d'); // Creates a contect object
                                                        myCanvas.width = myImage.width; // Assigns image's width to canvas
                                                        myCanvas.height = myImage.height; // Assigns image's height to canvas
                                                        myContext.drawImage(myImage, 0, 0); // Draws the image on canvas
                                                        let imgData = myCanvas.toDataURL('image/jpeg', 1); // Assigns image base64 string in jpeg format to a variable
                                                        setPhotoOutsideBase64(imgData);
                                                    };
                                                };
                                            }
                                        }}
                                    />
                                </label>
                                <canvas id="canvasOutside" width="1000" height="700"></canvas>
                                <textarea
                                    className="request-form__input request-form__input--textarea"
                                    name="Vermogen"
                                    aria-label="Vermogen"
                                    required
                                    placeholder="Welk vermogen zoekt u voor de nieuwe hybride aggregaat in kVA?"
                                />
                                <button className="request-form__submit" aria-label="Verzenden" type="submit">
                                    Verzend
                                </button>
                            </form>
                        )}
                    </div>
                </div>
            </section>
        </main>
    );
};

export default GreenDeals;
